import { createElement, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC } from 'app/sorting';

const Headers = ({ config, dataset }) => {
  return (
    <thead className="table-light">
      <tr>
        {
          dataset.fields.map(field => {
            return config.sorting && !field.noSorting
              ? <Header key={field.name} dataset={dataset} field={field} />
              : <NoSortHeader key={field.name} field={field} />;
          })
        }
      </tr>
    </thead>
  );
};

const Header = ({ dataset, field }) => {

  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(dataset.sort(dataset.id, field));
  }, [ dataset, dispatch, field ]);

  return (
    <th className="pointer" onClick={onClick}>
      <span className="me-1">{field.header}</span>
      <SortIcon field={field} />
    </th>
  );
};

const SortIcon = ({ field }) => {
  let icon = makeIcon(field);
  return <FontAwesomeIcon icon={icon.name} color={icon.color} size="sm" />;
};

const makeIcon = field => {
  switch (field.orderedBy) {
    case ASC:
      return { name: 'sort-up', color: '#555' };
    case DESC:
      return { name: 'sort-down', color: '#555' };
    default:
      return { name: 'sort', color: '#aaa' };
  }
};

const NoSortHeader = ({ field }) => {
  return (
    <th>
      <span className="me-1">{field.header}</span>
    </th>
  );
};

const Body = ({ config, data, fields }) => {
  return (
    <tbody>
      {
        data.map(record => (
          <Row
            key={record[config.recordKey]}
            config={config}
            fields={fields}
            record={record}
          />
        ))
      }
    </tbody>
  );
};

const Row = ({ config, fields, record }) => {
  return (
    <tr className={config.rowClassNameGenerator(record)}>
      {
        fields.map(field => (
          <Cell
            key={`${record[config.recordKey]}-${field.name}`}
            config={config}
            field={field}
            record={record}
          />
        ))
      }
    </tr>
  );
};

const Cell = ({ config, field, record }) => {
  return <td className={field.cellClassname || ''}>{render(record, field, config)}</td>;
};

const render = (record, field, config) => {
  switch (field.type) {
    case 'component':
      return createElement(field.Component, { record });
    case 'custom':
      return field.render(record);
    case 'input':
      return <Input config={config} field={field} record={record} />;
    default:
      return record[field.name];
  }
};

const Input = ({ config, field, record }) => {

  const dispatch = useDispatch();
  const [ value, setValue ] = useState(record[field.name] || '');

  const onChange = useCallback(e => setValue(e.target.value), [ setValue ]);

  const onBlur = useCallback(e => {
    dispatch(field.blurAction(record, e.target.value));
  }, [ dispatch, field, record ]);

  return (
    <input
      type="text"
      className="text-input text-center"
      id={`${field.name}-${record[config.recordKey]}`}
      onBlur={onBlur}
      onChange={onChange}
      size="5"
      value={value}
    />
  );
};

const DataTable = ({ dataset, filters, config }) => {

  const _config = {
    header: true,
    sorting: true,
    syncQueue: true,
    recordKey: 'id',
    classNames: 'border-sides',
    prependFields: [],
    rowClassNameGenerator: () => '',
    ...config
  };

  dataset.fields = [ ..._config.prependFields, ...dataset.fields ];

  const filtered = dataset.data.filter(datum => (filters || []).every(filter => filter.evaluate(datum)));

  return (
    <table className={`table table-hover align-middle text-center ${_config.classNames}`}>
      { !!_config.header && <Headers config={_config} dataset={dataset} /> }
      <Body config={_config} data={filtered} fields={dataset.fields} />
    </table>
  );
};

export default DataTable;
