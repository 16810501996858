import { DataTable } from 'app/components';
import { useSlateTeamsFilter } from 'app/slates';

const TeamStats = ({ slate, dataset }) => {

  const filters = useSlateTeamsFilter(slate);

  return (
    <div className="my-4">
      <DataTable dataset={dataset} filters={filters} config={{ classNames: 'border' }} />
    </div>
  );
};

export default TeamStats;
