import { ClearStateLink } from 'app/components';

const Footer = () => {
  return (
    <footer className="container-fluid">
      <div className="row bg-primary text-light">
        <div className="col px-5 py-2">
          <span>Footer Text</span>
        </div>
        <div className="col px-5 py-2 text-end">
          <ClearStateLink />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
