import { useCallback } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ALL, CLASSIC, DST, FLEX, K, QB, RB, TE, WR } from 'app/constants';
import { putFilter, useFilterData } from 'app/filters';
import { useSlate } from 'app/slates';

export const POSITION = 'position';

const putPositionFilter = (slateId, position) => putFilter({
  slateId,
  filter: {
    [POSITION]: {
      data: position
    }
  }
});

export const usePositionFilterData = slate => {
  return useFilterData(slate, POSITION)?.data || '';
};

const CLASSIC_POSITIONS = [
  { title: ALL, value: '' },
  { title: QB, value: QB },
  { title: RB, value: RB },
  { title: WR, value: WR },
  { title: TE, value: TE },
  { title: FLEX, value: FLEX },
  { title: DST, value: DST }
];

const SHOWDOWN_POSITIONS = [
  { title: ALL, value: '' },
  { title: QB, value: QB },
  { title: RB, value: RB },
  { title: WR, value: WR },
  { title: TE, value: TE },
  { title: K, value: K },
  { title: DST, value: DST }
];

const SlatePlayersPositionFilter = () => {

  const slate = useSlate();
  const dispatch = useDispatch();
  const activeTab = usePositionFilterData(slate);
  const onSelect = useCallback(position => dispatch(putPositionFilter(slate.id, position)), [ dispatch, slate ]);
  const positions = slate.contestType === CLASSIC ? CLASSIC_POSITIONS : SHOWDOWN_POSITIONS;

  return (
    <div className="row">
      <div className="col">
        <Tabs activeKey={activeTab} onSelect={onSelect}>
          { positions.map(p => <Tab key={p.title} eventKey={p.value} title={p.title} />) }
        </Tabs>
      </div>
    </div>
  );
};

export default SlatePlayersPositionFilter;
