import { FLEX1, FLEX2, FLEX3, FLEX4, FLEX5, SHOWDOWN_POSITIONS } from '../constants';
import { CPT, FLEX } from 'app/constants';
import Lineup from './Lineup';

const ShowdownLineup = class extends Lineup {

  constructor (lineup = {}) {
    super(lineup);
    this._positions = SHOWDOWN_POSITIONS;
  }

  hasOpen (position) {
    if (position === CPT) {
      return !this._players[CPT];
    } else if (position === FLEX) {
      return [ FLEX1, FLEX2, FLEX3, FLEX4, FLEX5 ].some(f => !this._players[f]);
    } else {
      return false;
    }
  }

  put (player) {
    if (player.slatePosition === CPT) {
      this._players[player.slatePosition] = player;
    } else if (player.slatePosition === FLEX) {
      this._putFlex(player);
    } else {
      throw new Error(`attempting to put player w/ invalid slatePosition: ${player.slatePosition}`);
    }
  }

  _putFlex (player) {
    if (!this._players[FLEX1]) {
      this._players[FLEX1] = player;
    } else if (!this._players[FLEX2]) {
      this._players[FLEX2] = player;
    } else if (!this._players[FLEX3]) {
      this._players[FLEX3] = player;
    } else if (!this._players[FLEX4]) {
      this._players[FLEX4] = player;
    } else if (!this._players[FLEX5]) {
      this._players[FLEX5] = player;
    }
  }
};

export default ShowdownLineup;
