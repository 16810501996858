import { FilterToggleBox } from 'app/components';
import { clearStatusFilter, putStatusFilter, useStatusFilterIsEnabled } from './redux';

const StatusFilter = ({ slate }) => {

  return (
    <FilterToggleBox
      label="Status"
      slate={slate}
      enabled={useStatusFilterIsEnabled(slate)}
      putFilter={putStatusFilter}
      clearFilter={clearStatusFilter}
    />
  );
};

export default StatusFilter;
