import { createElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LOGIN } from 'app/views';

const Public = () => {
  return (
    <Routes>
      <Route key={LOGIN.id} path={LOGIN.route} element={createElement(LOGIN.component)} />
      <Route path="*" element={<Navigate to={LOGIN.route} replace />} />
    </Routes>
  );
};

export default Public;
