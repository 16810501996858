export const arraysEqual = (a1, a2) => {
  if (a1.length === 0 && a2.length === 0) {
    return true;
  } else if (a1.length !== a2.length) {
    return false;
  }
  a1.sort();
  a2.sort();
  for (let i = 0; i < a1.length; i++) {
    if (a1[i] !== a2[i]) {
      return false;
    }
  }
  return true;
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0
});

export const asUsDollars = amount => {
  return isNaN(amount) ? '' : formatter.format(amount);
};

export const epochTime = () => Math.floor(new Date().getTime() / 1000);

export const exportFile = (content, contentType, filename) => {
  const file = new Blob([ content ], { type: contentType });
  const a = document.createElement('a');
  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();
  URL.revokeObjectURL(a.href);
};

export const findByTeam = (data, ...teams) => Object.values(data).filter(datum => teams.includes(datum.team));
