import { createContext, useContext, useReducer } from 'react';
import { useGames } from './redux';

const GamesViewContext = createContext(null);
const GamesViewDispatchContext = createContext(null);

const gamesViewReducer = (state, action) => {
  if (action.type === SELECTED_GAME) {
    return { ...state, [action.slateId]: { ...state[action.slateId], game: action.game } };
  } else if (action.type === HOME_OR_AWAY) {
    return { ...state, [action.slateId]: { ...state[action.slateId], homeOrAway: action.homeOrAway } };
  } else {
    throw Error('Unknown action: ' + JSON.stringify(action));
  }
};

export const GamesViewProvider = ({ children }) => {
  const [ gamesView, dispatch ] = useReducer(gamesViewReducer, {});
  return (
    <GamesViewContext.Provider value={gamesView}>
      <GamesViewDispatchContext.Provider value={dispatch}>
        { children }
      </GamesViewDispatchContext.Provider>
    </GamesViewContext.Provider>
  );
};

export const useSelectedGame = slate => {
  const games = useGames(slate);
  return useContext(GamesViewContext)[slate.id]?.game || games[0];
};

const SELECTED_GAME = 'SELECTED_GAME';
export const useSelectedGameDispatch = () => {
  const dispatch = useContext(GamesViewDispatchContext);
  return (slate, game) => dispatch({
    type: SELECTED_GAME,
    slateId: slate.id,
    game
  });
};

export const useHomeOrAway = slate => {
  return useContext(GamesViewContext)[slate.id]?.homeOrAway;
};

const HOME_OR_AWAY = 'HOME_OR_AWAY';
export const useHomeAwayDispatch = () => {
  const dispatch = useContext(GamesViewDispatchContext);
  return (slate, homeOrAway) => dispatch({
    type: HOME_OR_AWAY,
    slateId: slate.id,
    homeOrAway
  });
};
