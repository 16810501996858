import { CPT, DST, FLEX, QB, RB, TE, WR } from 'app/constants';

export const SALARY_CAP = 50000;

export const RB1 = 'RB1';
export const RB2 = 'RB2';
export const WR1 = 'WR1';
export const WR2 = 'WR2';
export const WR3 = 'WR3';

export const FLEX1 = 'FLEX1';
export const FLEX2 = 'FLEX2';
export const FLEX3 = 'FLEX3';
export const FLEX4 = 'FLEX4';
export const FLEX5 = 'FLEX5';

export const CLASSIC_POSITIONS = { QB: QB, [RB1]: RB, [RB2]: RB, [WR1]: WR, [WR2]: WR, [WR3]: WR, TE: TE, FLEX: FLEX, DST: DST };
export const SHOWDOWN_POSITIONS = { CPT: CPT, [FLEX1]: FLEX, [FLEX2]: FLEX, [FLEX3]: FLEX, [FLEX4]: FLEX, [FLEX5]: FLEX };
