import { createContext, useCallback, useContext, useReducer } from 'react';
import { Modal } from 'react-bootstrap';

const ModalMessageContext = createContext(null);
const ModalMessageDispatchContext = createContext(null);

const MESSAGES = {
  optimizer: {
    header: 'Unable to generate an optimal lineup',
    body: [
      `
      The optimizer only considers players that are liked or locked and have points assigned. Additionally, valid
      lineups must have a total salary that is at or under the cap. It is likely that you don't have a combination of
      players that meet both of these requirements.
      `,
      `
      If you believe you do and this error persists, please contact us for assistance.
      `
    ]
  }
};

/*
 * actions
 */
const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const useShowOptimizerMessageDispatch = () => {
  const dispatch = useContext(ModalMessageDispatchContext);
  return () => dispatch({
    type: SHOW_MESSAGE,
    message: MESSAGES.optimizer
  });
};

const HIDE_MODAL = 'HIDE_MODAL';
const useHideModalDispatch = () => {
  const dispatch = useContext(ModalMessageDispatchContext);
  return () => dispatch({
    type: HIDE_MODAL
  });
};

/*
 * reducer
 */
const INITIAL_STATE = { show: false, message: {} };
const modalMessageReducer = (state, action) => {
  if (action.type === SHOW_MESSAGE) {
    return { show: true, message: action.message };
  } else if (action.type === HIDE_MODAL) {
    return INITIAL_STATE;
  } else {
    throw Error('Unknown action: ' + JSON.stringify(action));
  }
};

/*
 *
 */
const useShowModal = () => {
  return useContext(ModalMessageContext).show;
};

const useMessage = () => {
  return useContext(ModalMessageContext).message;
};

const ModalMessage = () => {

  const show = useShowModal();
  const message = useMessage();

  const dispatchHideModal = useHideModalDispatch();
  const onHide = useCallback(() => dispatchHideModal(), [ dispatchHideModal ]);

  return (
    <Modal
      centered
      show={show}
      animation={false}
      onHide={onHide}
      dialogClassName="message-modal"
    >
      <Modal.Header closeButton>
        <h2>{ message.header }</h2>
      </Modal.Header>
      <Modal.Body>
        { // eslint-disable-next-line react/no-array-index-key
          message.body?.map((p, i) => <p key={i}>{ p }</p>)
        }
      </Modal.Body>
    </Modal>
  );
};

/*
 * component
 */
const ModalMessageProvider = ({ children }) => {
  const [ teamModal, dispatch ] = useReducer(modalMessageReducer, INITIAL_STATE);
  return (
    <ModalMessageContext.Provider value={teamModal}>
      <ModalMessageDispatchContext.Provider value={dispatch}>
        { children }
        <ModalMessage />
      </ModalMessageDispatchContext.Provider>
    </ModalMessageContext.Provider>
  );
};

export default ModalMessageProvider;
