import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFields } from 'app/players/fields';

export const useAnalysisFields = () => [
  {
    name: 'slatePosition',
    header: 'pos',
    order: 1,
    orderedBy: 0
  },
  ...useFields([
    'name',
    'team',
    'opponent',
    'salary',
    'target'
  ]),
  {
    name: 'count',
    header: 'count',
    order: -1,
    orderedBy: 0
  },
  {
    name: 'diff',
    header: 'diff',
    order: 1,
    orderedBy: 0
  },
  {
    name: 'percentage',
    type: 'custom',
    header: '%',
    order: -1,
    orderedBy: 0,
    render: player => `${player.percentage}%`
  }
];

export const useLineupFields = () => [
  {
    name: 'slatePosition',
    header: 'pos',
    cellClassname: 'text-start'
  },
  ...useFields([
    'name',
    'team',
    'opponent',
    'salary'
  ])
];

export const useLineupEditorFields = removeFromLineup => [
  ...useLineupFields(),
  {
    name: 'remove',
    type: 'component',
    header: '',
    Component: ({ record }) => {
      return (
        <span>
          {
            record.salary
              ? <FontAwesomeIcon icon="fa-solid fa-circle-minus" color="#f00" size="xl" className="pointer" onClick={removeFromLineup(record)} />
              : <span />
          }
        </span>
      );
    }
  }
];
