export const OptimizerLineup = class {

  constructor (positions, salaryCap, players = []) {
    this._positions = positions;
    this._salaryCap = salaryCap;
    this._players = players;
  }

  add (player) {
    this._players.push(player);
    return this;
  }

  isValid () {
    return this.playerCount() === this._positions.length && this.payroll() <= this._salaryCap;
  }

  isFeasible (player) {
    return this.doesNotContain(player) && this.withinSalaryCap(player);
  }

  doesNotContain (player) {
    return !this._players.find(p => p.id === player.id);
  }

  withinSalaryCap (player) {
    return this.payroll() + player.salary <= this._salaryCap;
  }

  payroll () {
    return this._players.reduce((total, player) => total + player.salary, 0);
  }

  playerCount () {
    return this._players.filter(p => p.id).length;
  }

  players () {
    return [ ...this._players ];
  }

  points () {
    return this._players.reduce((total, player) => total + player.points, 0);
  }

  potentialPoints (player) {
    return this.points() + player.points;
  }

  remainingSalary () {
    return this._salaryCap - this.payroll();
  }
};
