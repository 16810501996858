import { useHomeAwayFilter } from './HomeAwayFilter';
import { InjuryReport } from 'app/injuries';
import { useSlate } from 'app/slates';

const InjuryReportTab = () => {

  const slate = useSlate();
  const additionalFilters = [ useHomeAwayFilter(slate) ];

  return <InjuryReport slate={slate} additionalFilters={additionalFilters} />;
};

export default InjuryReportTab;
