import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { putFilter, useFilterData } from 'app/filters';

export const SALARY = 'salary';

const putSalaryFilter = (slateId, min, max) => putFilter({
  slateId,
  filter: {
    [SALARY]: {
      data: { min, max }
    }
  }
});

const useSalaryFilterData = slate => {
  return useFilterData(slate, SALARY)?.data || {};
};

const SalaryFilter = ({ slate }) => {

  const dispatch = useDispatch();
  const salary = useSalaryFilterData(slate);
  const min = salary?.min || '';
  const max = salary?.max || '';

  const onChangeMin = useCallback(e => {
    dispatch(putSalaryFilter(slate.id, e.target.value, max));
  }, [ dispatch, max, slate ]);

  const onChangeMax = useCallback(e => {
    dispatch(putSalaryFilter(slate.id, min, e.target.value));
  }, [ dispatch, min, slate ]);

  return (
    <span className="me-5">
      <span className="font-weight-bold me-3">Salary:</span>
      <label className="me-3">
        <span className="me-1">min</span>
        <input
          type="text"
          id="salary-filter-min-input"
          onChange={onChangeMin}
          size="6"
          value={min}
        />
      </label>
      <label>
        <span className="me-1">max</span>
        <input
          type="text"
          id="salary-filter-max-input"
          onChange={onChangeMax}
          size="6"
          value={max}
        />
      </label>
    </span>
  );
};

export default SalaryFilter;
