export const get = endpoint => {
  return _fetch(`${endpoint}`, { method: 'GET' });
};

export const post = (resource, payload = {}) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  };
  return _fetch(`/${resource}`, options);
};

const _fetch = (resource, options) => {
  return fetch(resource, options)
    .then(response => {
      if (!response.ok) {
        throw response;
      }
      return response;
    });
};
