import { useSelector } from 'react-redux';
import { useDefenseStatsFields, useOffenseStatsFields } from './fields';
import { sortDefenseStats, sortOffenseStats } from 'app/api';
import { sort, sortedFields } from 'app/sorting';


/*
 * hooks
 */
export const useTeams = week => {
  return useSelector(({ teams }) => teams.byWeek[week]);
};

export const useTeam = (team, week) => useTeams(week)[team];

export const useDepthChart = (team, week) => useTeams(week)[team].depthChart;

export const useGames = (team, week) => {
  const games = useSelector(({ teams }) => teams.games);
  return games[team].filter(datum => datum.week < week);
};

const useUnit = (team, unit, week) => {
  const teams = useTeams(week);
  return { ...teams[team], ...teams[team][unit] };
};

export const useOffense = (team, week) => useUnit(team, 'offense', week);

export const useDefense = (team, week) => useUnit(team, 'defense', week);

const useUnitStats = (unit, week) => {
  const teams = useTeams(week);
  const stats = useSelector(({ teams }) => teams.stats[unit][week]);
  return {
    ...stats,
    data: stats.data.map(id => ({ ...teams[id], ...teams[id][unit] }))
  };
};

export const useOffenseStatsDataset = week => {
  const offense = useUnitStats('offense', week);
  return {
    id: week,
    data: offense.sortField ? sort(offense.data, offense.sortField) : offense.data,
    fields: sortedFields(useOffenseStatsFields(), offense.sortField),
    sort: sortOffenseStats
  };
};

export const useDefenseStatsDataset = week => {
  const defense = useUnitStats('defense', week);
  return {
    id: week,
    data: defense.sortField ? sort(defense.data, defense.sortField) : defense.data,
    fields: sortedFields(useDefenseStatsFields(), defense.sortField),
    sort: sortDefenseStats
  };
};
