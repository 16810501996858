import { useCallback } from 'react';
import { CheckBox } from 'app/components';

const FilterToggleBox = ({ label, slate, enabled, putFilter, clearFilter }) => {

  const action = useCallback(() => {
    return enabled ? clearFilter(slate.id) : putFilter(slate.id);
  }, [ clearFilter, enabled, putFilter, slate ]);

  return (
    <span className="me-5">
      <CheckBox action={action} checked={enabled} label={label} />
    </span>
  );
};

export default FilterToggleBox;
