import { useSelector } from 'react-redux';

export { useLikedPlayers, useLockedPlayers, useSlatePlayer } from './slates/redux';

/*
 * hooks
 */
export const usePlayers = week => useSelector(({ players }) => players.byWeek[week]);

export const usePlayerGames = (player, week) => {
  const games = useSelector(({ players }) => players.games);
  const data = games[player?.id];
  return data && data.filter(datum => datum.week < week);
};
