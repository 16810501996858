import { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelectedPlayerId, useShowPlayerModal, useShowPlayerModalDispatch } from './PlayerModalContext';
import { useSlatePlayer } from '../slates/redux';
import { useSlate } from 'app/slates';
import PlayerModalView from './PlayerModalView';

const PlayerModal = () => {

  const slate = useSlate();
  const playerId = useSelectedPlayerId();
  const player = useSlatePlayer(slate, playerId);

  const showModal = useShowPlayerModal();
  const dispatchShow = useShowPlayerModalDispatch();
  const onHide = useCallback(() => dispatchShow(false), [ dispatchShow ]);

  return (
    <Modal
      centered
      show={showModal}
      animation={false}
      onHide={onHide}
      dialogClassName="data-modal"
      aria-labelledby="data-modal-title"
    >
      { player ? <PlayerModalView player={player} /> : <div /> }
    </Modal>
  );
};

const PlayerModalWrapper = () => {
  return !!useSlate() && <PlayerModal />;
};

export default PlayerModalWrapper;
