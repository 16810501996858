import { useCallback } from 'react';

const ClearStateLink = () => {

  const clearState = useCallback(() => {
    localStorage.clear();
    window.location.reload();
  }, []);

  return <span className="link m-3 text-light" onClick={clearState}>Clear All Application State</span>;
};

export default ClearStateLink;
