import { useCallback } from 'react';
import { useSelectedPlayerIdDispatch, useShowPlayerModalDispatch } from 'app/players';

const useInjuryReportFields = () => {
  const select = useSelectedPlayerIdDispatch();
  const showModal = useShowPlayerModalDispatch();

  return [
    {
      name: 'name',
      type: 'component',
      header: 'name',
      cellClassname: 'text-start text-nowrap',
      order: 1,
      orderedBy: 0,
      Component: ({ record }) => {
        const onClick = useCallback(() => {
          select(record.id);
          showModal(true);
        }, [ record ]);
        return <span className="link" onClick={onClick}>{record.name}</span>;
      }
    },
    {
      name: 'position',
      header: 'pos',
      order: 1,
      orderedBy: 0
    },
    {
      name: 'positionGroup',
      header: 'grp',
      order: 1,
      orderedBy: 0
    },
    {
      name: 'team',
      header: 'tm',
      order: 1,
      orderedBy: 0
    },
    {
      name: 'gamesPlayed',
      header: 'gms',
      order: -1,
      orderedBy: 0
    },
    {
      name: 'avgPrimarySnapsPercent',
      header: 'avgSnap%',
      order: -1,
      orderedBy: 0
    },
    {
      name: 'status',
      header: 'status',
      order: 1,
      orderedBy: 0
    },
    {
      name: 'nature',
      header: 'nature',
      order: 1,
      orderedBy: 0
    },
    {
      name: 'monday',
      header: 'mon',
      order: 1,
      orderedBy: 0
    },
    {
      name: 'tuesday',
      header: 'tue',
      order: 1,
      orderedBy: 0
    },
    {
      name: 'wednesday',
      header: 'wed',
      order: 1,
      orderedBy: 0
    },
    {
      name: 'thursday',
      header: 'thu',
      order: 1,
      orderedBy: 0
    },
    {
      name: 'friday',
      header: 'fri',
      order: 1,
      orderedBy: 0
    },
    {
      name: 'saturday',
      header: 'sat',
      order: 1,
      orderedBy: 0
    }
  ];
};

export default useInjuryReportFields;
