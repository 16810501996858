import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CheckBox = ({ action, checked, label }) => {

  const dispatch = useDispatch();
  const color = checked ? '#28a745' : '#ccc';
  const onClick = useCallback(() => dispatch(action()), [ action, dispatch ]);

  return (
    <span className="pointer" onClick={onClick}>
      <span className="font-weight-bold">{label}: </span>
      <FontAwesomeIcon icon="fa-solid fa-square-check" className="px-1" color={color} />
    </span>
  );
};

export default CheckBox;
