import { putAvgSnapPercentageFilter, useAvgSnapPercentageFilterData } from './redux';
import { TextInputFilter } from 'app/components';

const AvgSnapPercentageFilter = ({ slate }) => {

  const percentage = useAvgSnapPercentageFilterData(slate);

  return (
    <TextInputFilter
      id="avg-snap-filter"
      label="Snap %"
      putFilter={putAvgSnapPercentageFilter}
      slate={slate}
      value={percentage}
    />
  );
};

export default AvgSnapPercentageFilter;
