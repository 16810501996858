import { useSelector } from 'react-redux';
import { usePositionVsTeamFields } from './fields';

/*
 * hooks
 */
export const usePositionVsTeamDataset = (position, team, week) => {
  const positionVsTeam = useSelector(({ pvt }) => pvt);
  const data = !!position && !!team && positionVsTeam[position]?.[team].filter(datum => datum.week < week);
  const fields = usePositionVsTeamFields(position);
  return data && { data, fields };
};
