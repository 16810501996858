import { FilterToggleBox } from 'app/components';
import { clearFilter, putFilter, useFilterData } from 'app/filters';

export const INTERESTS = 'interests';

const putInterestsFilter = slateId => putFilter({
  slateId,
  filter: {
    [INTERESTS]: {}
  }
});

const clearInterestsFilter = slateId => clearFilter(slateId, INTERESTS);

const useInterestsFilterIsEnabled = slate => {
  return !!useFilterData(slate, INTERESTS);
};

const InterestsFilter = ({ slate }) => {

  return (
    <FilterToggleBox
      label="Likes/Locks"
      slate={slate}
      enabled={useInterestsFilterIsEnabled(slate)}
      putFilter={putInterestsFilter}
      clearFilter={clearInterestsFilter}
    />
  );
};

export default InterestsFilter;
