import { useCallback, useMemo } from 'react';
import { useLineupEditorContext } from './LineupEditorContext';
import { FLEX1, FLEX2, FLEX3, FLEX4, FLEX5, RB1, RB2, WR1, WR2, WR3 } from '../constants';
import { useLineupEditorFields } from '../fields';
import { DataTable } from 'app/components';
import { CLASSIC, CPT, DST, FLEX, QB, TE } from 'app/constants';
import { useSlate } from 'app/slates';
import useLineupFactory from '../LineupFactory';

const LineupColumn = ({ players, remove }) => {

  const dataset = { data: players, fields: useLineupEditorFields(remove) };
  const tableConfig = { header: false, sorting: false, classNames: 'table-borderless' };

  return (
    <div className="col-4 lineup-column">
      <DataTable dataset={dataset} config={tableConfig} />
    </div>
  );
};

const LineupPlayers = () => {

  const slate = useSlate();
  const lineupFactory = useLineupFactory(slate);

  const positions = useMemo(() => {
    return slate.contestType === CLASSIC
      ? [ [ QB, RB1, RB2 ], [ WR1, WR2, WR3 ], [ TE, FLEX, DST ] ]
      : [ [ CPT, FLEX1 ], [ FLEX2, FLEX3 ], [ FLEX4, FLEX5 ] ];
  }, [ slate ]);

  const { lineup, setLineup } = useLineupEditorContext();
  const remove = useCallback(player => () => {
    lineup.remove(player);
    setLineup(lineupFactory.make(lineup));
  }, [ lineup, lineupFactory, setLineup ]);

  return (
    <>
      { positions.map(group => <LineupColumn key={group[0]} players={lineup.get(group)} remove={remove} />) }
    </>
  );
};

export default LineupPlayers;
