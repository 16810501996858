import { TextInputFilter } from 'app/components';
import { putFilter, useFilterData } from 'app/filters';

export const AVG_TIMES_FOUR = 'avgTimesFour';

const putAvgTimesFourFilter = (slateId, percentage) => putFilter({
  slateId,
  filter: {
    [AVG_TIMES_FOUR]: {
      data: percentage
    }
  }
});

const useAvgTimesFourFilterData = slate => {
  return useFilterData(slate, AVG_TIMES_FOUR)?.data || '';
};

const AvgTimesFourFilter = ({ slate }) => {

  const percentage = useAvgTimesFourFilterData(slate);

  return (
    <TextInputFilter
      id="avg-x-four-filter"
      label="Avg/x4 %"
      putFilter={putAvgTimesFourFilter}
      slate={slate}
      value={percentage}
    />
  );
};

export default AvgTimesFourFilter;
