import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { View } from './view';
import './App.scss';
import './svg/icons';

const App = ({ store }) => {
  return (
    <Provider store={store}>
      <Router>
        <View />
      </Router>
    </Provider>
  );
};

export default App;
