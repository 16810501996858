import { TextInputFilter } from 'app/components';
import { putFilter, useFilterData } from 'app/filters';

export const MAX_TIMES_FOUR = 'maxTimesFour';

const putMaxTimesFourFilter = (slateId, percentage) => putFilter({
  slateId,
  filter: {
    [MAX_TIMES_FOUR]: {
      data: percentage
    }
  }
});

const useMaxTimesFourFilterData = slate => {
  return useFilterData(slate, MAX_TIMES_FOUR)?.data || '';
};

const MaxTimesFourFilter = ({ slate }) => {

  const percentage = useMaxTimesFourFilterData(slate);

  return (
    <TextInputFilter
      id="max-x-four-filter"
      label="Max/x4 %"
      putFilter={putMaxTimesFourFilter}
      slate={slate}
      value={percentage}
    />
  );
};

export default MaxTimesFourFilter;
