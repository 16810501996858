import { useSelector } from 'react-redux';
import { AVG_TIMES_FOUR } from './AvgTimesFourFilter';
import { INTERESTS } from './InterestsFilter';
import { MAX_TIMES_FOUR } from './MaxTimesFourFilter';
import { POINTS } from './PointsFilter';
import { SALARY } from './SalaryFilter';
import { POSITION } from './SlatePlayersPositionFilter';
import { TARGETS } from './TargetsFilter';
import { useSlatePlayersFields } from '../fields';
import { sortSlatePlayers } from 'app/api';
import { CLASSIC, CPT, RB, SHOWDOWN, TE, WR } from 'app/constants';
import { putFilter, useFilterData, useRegisteredFilters } from 'app/filters';
import { sort, sortedFields } from 'app/sorting';
import { useSlatePlayersFieldGroup } from 'app/slates';
import { fourTimesValue } from 'app/util/calculations';


/*
 * hooks
 */
export const useSlatePlayersDataset = slate => {
  const data = useSlatePlayers(slate);
  const sortField = useSelector(({ players }) => players.slates[slate.id]).sortField;
  return {
    id: slate.id,
    data: sortField ? sort(data, sortField) : data,
    fields: sortedFields(useSlatePlayersFields(useSlatePlayersFieldGroup()), sortField),
    sort: sortSlatePlayers
  };
};

export const useSlatePlayers = slate => {
  const slatePlayers = useSelector(({ players }) => players.slates[slate.id].data);
  const players = useSelector(({ players }) => players.byWeek[slate.week]);
  const customLookup = useCustomLookup(slate.id);
  const interestsLookup = useInterestsLookup(slate.id);
  return slatePlayers.map(sp => ({
    ...sp,
    ...players[sp.id],
    ...customLookup(sp.vendorId),
    ...interestsLookup(sp.vendorId)
  }));
};

export const useSlatePlayer = (slate, playerId) => {
  const slatePlayers = useSelector(({ players }) => players.slates[slate.id].data);
  const showdownPosition = useShowdownPositionFilterData(slate);
  const slatePlayer = slate.contestType === CLASSIC
    ? slatePlayers.find(sp => sp.id === playerId)
    : slatePlayers.find(sp => sp.id === playerId && sp.slatePosition === showdownPosition);
  const player = useSelector(({ players }) => players.byWeek[slate.week][playerId]);
  const customLookup = useCustomLookup(slate.id);
  const interestsLookup = useInterestsLookup(slate.id);
  return {
    ...slatePlayer,
    ...player,
    ...customLookup(slatePlayer?.vendorId),
    ...interestsLookup(slatePlayer?.vendorId)
  };
};

const useCustomLookup = slateId => {
  const custom = useSelector(({ players }) => players.custom[slateId]) || {};
  return vendorId => ({ points: 0, target: 0, ...custom[vendorId] });
};

const useInterestsLookup = slateId => {
  const interests = useSelector(({ players }) => players.interests[slateId]) || {};
  return vendorId => ({ interest: interests[vendorId] || 0 });
};

const LIKED = 1;
export const useLikedPlayers = slate => useSlatePlayers(slate).filter(p => p.interest === LIKED);

const LOCKED = 2;
export const useLockedPlayers = slate => useSlatePlayers(slate).filter(p => p.interest === LOCKED);

/*
 * filter actions
 */
const AVG_SNAP_PERCENTAGE = 'avgSnapPercentage';
export const putAvgSnapPercentageFilter = (slateId, percentage) => putFilter({
  slateId,
  filter: {
    [AVG_SNAP_PERCENTAGE]: {
      data: percentage
    }
  }
});

const SHOWDOWN_POSITION = 'showdownPosition';
export const putShowdownPositionFilter = (slateId, position) => putFilter({
  slateId,
  filter: {
    [SHOWDOWN_POSITION]: {
      data: position
    }
  }
});


/*
 * filter hooks
 */
export const useSlatePlayersFilters = slate => {
  let filters = [ AVG_SNAP_PERCENTAGE, AVG_TIMES_FOUR, INTERESTS, MAX_TIMES_FOUR, POINTS, POSITION, SALARY, TARGETS ];
  let defaults = {};
  if (slate.contestType === SHOWDOWN) {
    filters.push(SHOWDOWN_POSITION);
    defaults = { [SHOWDOWN_POSITION]: { type: SHOWDOWN_POSITION, data: CPT } };
  }
  return useRegisteredFilters(slate, filters, defaults)
    .map(([ type, filter ]) => ({ type, data: filter.data }))
    .map(makeFilter);
};

export const usePositionFilter = slate => {
  return useRegisteredFilters(slate, [ POSITION ])
    .map(([ type, filter ]) => ({ type, data: filter.data }))
    .map(fd => {
      return {
        evaluate: player => !player[fd.type] || !fd.data || player.position === fd.data || (fd.data === 'FLEX' && [ RB, WR, TE ].includes(player.position))
      };
    });
};

export const useAvgSnapPercentageFilterData = slate => {
  return useFilterData(slate, AVG_SNAP_PERCENTAGE)?.data || '';
};

export const useShowdownPositionFilterData = slate => {
  return useFilterData(slate, SHOWDOWN_POSITION)?.data || CPT;
};


/*
 * filters helpers
 */
const methods = {
  [AVG_SNAP_PERCENTAGE]: fd => {
    return {
      evaluate: player => fd.data === '' || (player.avgPercentSnaps && fd.data <= player.avgPercentSnaps)
    };
  },
  [AVG_TIMES_FOUR]: fd => {
    return {
      evaluate: player => fd.data === '' || (player.avgFps && fd.data / 100 <= player.avgFps / fourTimesValue(player.salary))
    };
  },
  [INTERESTS]: () => {
    return {
      evaluate: player => player.salary && player.interest
    };
  },
  [MAX_TIMES_FOUR]: fd => {
    return {
      evaluate: player => fd.data === '' || (player.maxFps && fd.data / 100 <= player.maxFps / fourTimesValue(player.salary))
    };
  },
  [POINTS]: () => {
    return {
      evaluate: player => !!player.points
    };
  },
  [POSITION]: fd => {
    return {
      evaluate: player => !player[fd.type] || !fd.data || player.position === fd.data || (fd.data === 'FLEX' && [ RB, WR, TE ].includes(player.position))
    };
  },
  [SALARY]: fd => {
    return {
      evaluate: player => !player[fd.type] || ((!fd.data.min || player.salary >= parseInt(fd.data.min)) && (!fd.data.max || player.salary <= parseInt(fd.data.max)))
    };
  },
  [SHOWDOWN_POSITION]: fd => {
    return {
      evaluate: player => player.slatePosition === fd.data
    };
  },
  [TARGETS]: () => {
    return {
      evaluate: player => !!player.target
    };
  }
};

const makeFilter = fd => methods[fd.type](fd);
