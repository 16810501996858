import { SALARY_CAP } from '../constants';

const Lineup = class {

  constructor (lineup = {}) {
    this._id = lineup._id;
    this._positions = {};
    this._players = lineup._players ? { ...lineup._players } : {};
  }

  id (id) {
    if (!id) {
      return this._id;
    }
    this._id = id;
  }

  hasPlayer (player) {
    return Object.values(this._players).some(_player => _player.id === player.id);
  }

  hasOpen () {
    return false;
  }

  put (player) {
    this._players[player.position] = player;
  }

  get (arg) {
    if (Array.isArray(arg)) {
      return Object
        .keys(this._positions)
        .filter(k => arg.includes(k))
        .map(k => this.get(k));
    } else {
      return { id: arg, slatePosition: this._positions[arg], ...this._players[arg] };
    }
  }

  remove (player) {
    const position = Object
      .entries(this._players)
      .reduce((position, [ k, p ]) => p.id === player.id ? k : position, '');
    delete this._players[position];
  }

  players () {
    return { ...this._players };
  }

  payroll () {
    return Object.values(this._players).reduce((total, player) => total + player.salary, 0);
  }

  remainingSalary () {
    return SALARY_CAP - this.payroll();
  }

  remainingSalaryPerOpen () {
    const openPositions = this._openPositions();
    return openPositions > 0 ? Math.floor(this.remainingSalary() / openPositions) : 0;
  }

  isValid () {
    return this._hasNoDuplicates() && this._openPositions() === 0 && this.payroll() <= SALARY_CAP;
  }

  _hasNoDuplicates () {
    const seen = new Set();
    return !Object.values(this._players).some(player => seen.size === seen.add(player.id).size);
  }

  positions () {
    return this._positions;
  }

  _openPositions () {
    return Object.keys(this._positions).length - Object.values(this._players).length;
  }
};

export default Lineup;
