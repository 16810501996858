import LogInForm from './LogInForm';
import appText from 'app/text.json';

const LogInView = () => {

  const rowClasses = 'row mx-auto text-center justify-content-sm-center';
  const colClasses = 'col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4';
  const contentClasses = 'mt-5 mb-2 mx-auto py-3 border bg-white';

  return (
    <section className={`${rowClasses} mt-4 mb-1`}>
      <div className={colClasses}>
        <div className={contentClasses}>
          <h1 className="mb-3">DFS Distiller</h1>
          <h5 className="mb-4 pb-2 text-muted">{appText.slogan}</h5>
          <LogInForm />
        </div>
      </div>
    </section>
  );
};

export default LogInView;
