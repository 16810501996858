const ABBREVIATIONS = {
  'doubtful': 'D',
  'inactive': 'O',
  'ir': 'IR',
  'ir-r': 'IR',
  'nfi-r': 'NFI-R',
  'out': 'O',
  'pup-p': 'PUP',
  'pup-r': 'PUP',
  'questionable': 'Q',
  'reserve-dnr': 'Res',
  'reserve-ex': 'Res',
  'reserve-sus': 'Res'
};

export const indication = status => {
  const abbreviation = ABBREVIATIONS[status?.toLowerCase()] || '';
  return abbreviation ? ` [${abbreviation}]` : '';
};
