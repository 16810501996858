import { TextInputFilter } from 'app/components';
import { putMinGamesPlayedFilter, useMinGamesPlayedFilterData } from './redux';

const MinGamesPlayedFilter = ({ slate }) => {

  const gamesPlayed = useMinGamesPlayedFilterData(slate);

  return (
    <TextInputFilter
      id="min-games-played-filter"
      label="Min. Games"
      putFilter={putMinGamesPlayedFilter}
      slate={slate}
      value={gamesPlayed}
    />
  );
};

export default MinGamesPlayedFilter;
