import { useCallback } from 'react';
import { useSelectedTeamDispatch, useShowTeamModalDispatch } from './modal';
import { secondsToMinutes } from 'app/util/calculations';

const useFieldDictionary = () => {
  return {
    week: {
      name: 'week',
      header: 'wk'
    },
    versus: {
      name: 'versus',
      header: 'vs.'
    },
    score: {
      name: 'score',
      header: 'score'
    },
    completions: {
      name: 'completions',
      header: 'cmp'
    },
    passAttempts: {
      name: 'passAttempts',
      header: 'att'
    },
    passesPerGame: {
      name: 'passesPerGame',
      header: 'pass/gm',
      order: -1,
      orderedBy: 0
    },
    completionPercentage: {
      name: 'completionPercentage',
      header: 'cmp %',
      order: -1,
      orderedBy: 0
    },
    passYds: {
      name: 'passYds',
      header: 'yds'
    },
    tmPassYds: {
      name: 'passYds',
      header: 'passYds',
      order: 0,
      orderedBy: 0
    },
    ydsPerPass: {
      name: 'ydsPerPass',
      header: 'avg'
    },
    tmPassYdsPerGame: {
      name: 'passYdsPerGame',
      header: 'passYds/gm',
      order: -1,
      orderedBy: 0
    },
    passTds: {
      name: 'passTds',
      header: 'tds'
    },
    tmPassTds: {
      name: 'passTds',
      header: 'passTds',
      order: 0,
      orderedBy: 0
    },
    interceptions: {
      name: 'interceptions',
      header: 'ints'
    },
    rushAttempts: {
      name: 'rushAttempts',
      header: 'rsh'
    },
    rushesPerGame: {
      name: 'rushesPerGame',
      header: 'rsh/gm',
      order: -1,
      orderedBy: 0
    },
    rushYds: {
      name: 'rushYds',
      header: 'yds'
    },
    ydsPerRush: {
      name: 'ydsPerRush',
      header: 'avg'
    },
    avgRushYds: {
      name: 'avgRushYds',
      header: 'yds/rsh',
      order: -1,
      orderedBy: 0
    },
    rushYdsPerGame: {
      name: 'rushYdsPerGame',
      header: 'rshYds/gm',
      order: -1,
      orderedBy: 0
    },
    rushTds: {
      name: 'rushTds',
      header: 'tds',
      order: -1,
      orderedBy: 0
    },
    tmRushTds: {
      name: 'rushTds',
      header: 'rshTds',
      order: 0,
      orderedBy: 0
    },
    fumblesRecovered: {
      name: 'fumblesRecovered',
      header: 'fbl'
    },
    fumblesLost: {
      name: 'fumblesLost',
      header: 'fbl'
    },
    pointsPerGame: {
      name: 'pointsPerGame',
      header: 'pts/gm',
      order: -1,
      orderedBy: 0
    },
    sacks: {
      name: 'sacks',
      header: 'scks',
      order: -1,
      orderedBy: 0
    },
    sacksAllowed: {
      name: 'sacksAllowed',
      header: 'scks',
      order: 0,
      orderedBy: 0
    },
    turnovers: {
      name: 'turnovers',
      header: 'tos',
      order: -1,
      orderedBy: 0
    },
    fourthDownAttempts: {
      name: 'fourthDownAttempts',
      header: '4DAs',
      order: -1,
      orderedBy: 0
    },
    paceOfPlay: {
      name: 'paceOfPlay',
      header: 'pace',
      order: -1,
      orderedBy: 0
    },
    timeOfPossession: {
      name: 'timeOfPossession',
      type: 'custom',
      header: 'top',
      order: 0,
      orderedBy: 0,
      render: team => secondsToMinutes(team['timeOfPossession'])
    }
  };
};

const useFields = fieldIds => {
  const dict = useFieldDictionary();
  return fieldIds.map(id => dict[id]);
};

export const useOffenseGameFields = () => [
  ...useFields([
    'week',
    'versus',
    'score',
    'rushAttempts',
    'rushYds',
    'ydsPerRush',
    'rushTds',
    'completions',
    'passAttempts',
    'passYds',
    'ydsPerPass',
    'passTds',
    'interceptions',
    'fumblesLost',
    'sacksAllowed',
    'timeOfPossession'
  ])
];

export const useDefenseGameFields = () => [
  ...useFields([
    'week',
    'versus',
    'score',
    'rushAttempts',
    'rushYds',
    'ydsPerRush',
    'rushTds',
    'completions',
    'passAttempts',
    'passYds',
    'ydsPerPass',
    'passTds',
    'interceptions',
    'fumblesRecovered',
    'sacks',
    'timeOfPossession'
  ])
];

export const useOffenseStatsFields = () => {

  const dispatchSelectedTeam = useSelectedTeamDispatch();
  const dispatchShowTeamModal = useShowTeamModalDispatch();

  return [
    {
      name: 'name',
      type: 'component',
      header: 'tm',
      order: 1,
      orderedBy: 0,
      Component: ({ record }) => {
        const onClick = useCallback(() => {
          dispatchSelectedTeam({ ...record, unit: 'Offense' });
          dispatchShowTeamModal(true);
        }, [ record ]);
        return <span className="link" onClick={onClick}>{record.name}</span>;
      }
    },
    ...useFields([
      'pointsPerGame',
      'rushesPerGame',
      'rushYdsPerGame',
      'tmRushTds',
      'passesPerGame',
      'tmPassYdsPerGame',
      'tmPassTds',
      'sacksAllowed',
      'turnovers',
      'fourthDownAttempts',
      'paceOfPlay'
    ]),
    {
      name: 'avgTimeOfPossession',
      type: 'custom',
      header: 'avgTOP',
      order: -1,
      orderedBy: 0,
      render: team => secondsToMinutes(team['avgTimeOfPossession'])
    }
  ];
};

export const useDefenseStatsFields = () => {

  const dispatchSelectedTeam = useSelectedTeamDispatch();
  const dispatchShowTeamModal = useShowTeamModalDispatch();

  return [
    {
      name: 'name',
      type: 'component',
      header: 'tm',
      order: 1,
      orderedBy: 0,
      Component: ({ record }) => {
        const onClick = useCallback(() => {
          dispatchSelectedTeam({ ...record, unit: 'Defense' });
          dispatchShowTeamModal(true);
        }, [ record ]);
        return <span className="link" onClick={onClick}>{record.name}</span>;
      }
    },
    ...useFields([
      'pointsPerGame',
      'rushYdsPerGame',
      'avgRushYds',
      'tmRushTds',
      'tmPassYdsPerGame',
      'completionPercentage',
      'tmPassTds',
      'sacks',
      'turnovers'
    ]),
    {
      name: 'avgTimeOfPossession',
      type: 'custom',
      header: 'avgOppTOP',
      order: -1,
      orderedBy: 0,
      render: team => secondsToMinutes(team['avgTimeOfPossession'])
    }
  ];
};
