import { LogInView } from 'app/auth';
import { GamesView } from 'app/games';
import { LineupsEditorView, LineupsView } from 'app/lineups';
import { MatchupsView } from 'app/matchups';
import { PlayerStatsView, SlatePlayersView } from 'app/players';
import { DefensesView, OffensesView } from 'app/teams';

export const LOGIN = { id: 'login', route: '/login', component: LogInView };

export const PLAYERS = { id: 'players', route: '/players', name: 'Players', component: SlatePlayersView };
export const GAMES = { id: 'games', route: '/games', name: 'Games', component: GamesView };
export const MATCHUPS = { id: 'matchups', route: '/matchups', name: 'Matchups', component: MatchupsView };
export const LINEUPS = { id: 'lineups', route: '/lineups', name: 'Lineups', component: LineupsView };
export const LINEUPS_CREATOR = { id: 'lineups_creator', route: '/lineups/create', name: 'Create Lineup', component: LineupsEditorView };
export const LINEUPS_EDITOR = { id: 'lineups_editor', route: '/lineups/edit/:lineupId', name: 'Edit Lineup', component: LineupsEditorView };
export const PLAYER_STATS = { id: 'player_stats', route: '/player-stats', name: 'Player Stats', component: PlayerStatsView };
export const OFFENSES = { id: 'offenses', route: '/offenses', name: 'Offenses', component: OffensesView };
export const DEFENSES = { id: 'defenses', route: '/defenses', name: 'Defenses', component: DefensesView };

export const MENU_ITEMS = [ PLAYERS, GAMES, MATCHUPS, LINEUPS, PLAYER_STATS, OFFENSES, DEFENSES ];

export const SLATE_VIEWS = [ PLAYERS, GAMES, MATCHUPS, LINEUPS, LINEUPS_CREATOR, LINEUPS_EDITOR, PLAYER_STATS, OFFENSES, DEFENSES ];

export const DEFAULT_AUTHORIZED_VIEW = PLAYERS;
