import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const TextInputFilter = ({ id, label, putFilter, slate, value }) => {

  const dispatch = useDispatch();

  const onChange = useCallback(e => {
    dispatch(putFilter(slate.id, e.target.value));
  }, [ dispatch, putFilter, slate ]);

  return (
    <span className="me-5">
      <span className="font-weight-bold me-3">{label}:</span>
      <input
        type="text"
        id={id}
        onChange={onChange}
        size="6"
        value={value}
      />
    </span>
  );
};

export default TextInputFilter;
