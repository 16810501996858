import { Dropdown, DropdownButton } from 'react-bootstrap';
import { slatePlayersFieldGroups } from '../fields';
import { useSlatePlayersFieldGroup, useSlatePlayersFieldGroupDispatch } from 'app/slates';

const SlatePlayersFieldGroupSelector = () => {

  const fieldGroups = slatePlayersFieldGroups();
  const selected = useSlatePlayersFieldGroup();
  const dispatchFieldGroup = useSlatePlayersFieldGroupDispatch();

  const clickHandler = fg => () => dispatchFieldGroup(fg);

  return (
    <DropdownButton title={selected.text}>
      { fieldGroups.map(fg => <Dropdown.Item key={fg.id} onClick={clickHandler(fg)}>{fg.text}</Dropdown.Item>) }
    </DropdownButton>
  );
};

export default SlatePlayersFieldGroupSelector;
