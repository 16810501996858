import { Spinner } from 'react-bootstrap';

const LoadingGif = () => {
  return (
    <div className="h-100">
      <div className="row h-75 w-75 align-items-center justify-content-center">
        <Spinner animation="border" variant="secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
};

export default LoadingGif;
