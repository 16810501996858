import { createContext, useContext, useReducer } from 'react';
import PlayerModal from './PlayerModal';

const PlayerModalContext = createContext(null);
const PlayerModalDispatchContext = createContext(null);

/*
 * actions
 */
const SELECTED_PLAYER_ID = 'SELECTED_PLAYER_ID';
export const useSelectedPlayerIdDispatch = () => {
  const dispatch = useContext(PlayerModalDispatchContext);
  return playerId => dispatch({
    type: SELECTED_PLAYER_ID,
    playerId
  });
};

const SHOW_PLAYER_MODAL = 'SHOW_PLAYER_MODAL';
export const useShowPlayerModalDispatch = () => {
  const dispatch = useContext(PlayerModalDispatchContext);
  return show => dispatch({
    type: SHOW_PLAYER_MODAL,
    show
  });
};

/*
 * reducer
 */
const playerModalReducer = (state, action) => {
  if (action.type === SELECTED_PLAYER_ID) {
    return { ...state, playerId: action.playerId };
  } else if (action.type === SHOW_PLAYER_MODAL) {
    return { ...state, show: action.show };
  } else {
    throw Error('Unknown action: ' + JSON.stringify(action));
  }
};

/*
 * hooks
 */
export const useSelectedPlayerId = () => {
  return useContext(PlayerModalContext).playerId;
};

export const useShowPlayerModal = () => {
  return useContext(PlayerModalContext).show;
};

/*
 * component
 */
export const PlayerModalProvider = ({ children }) => {
  const [ playerModal, dispatch ] = useReducer(playerModalReducer, {});
  return (
    <PlayerModalContext.Provider value={playerModal}>
      <PlayerModalDispatchContext.Provider value={dispatch}>
        { children }
        <PlayerModal />
      </PlayerModalDispatchContext.Provider>
    </PlayerModalContext.Provider>
  );
};
