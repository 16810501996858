export const ALL = 'ALL';

export const CLASSIC = 'CLASSIC';
export const SHOWDOWN = 'SHOWDOWN';

export const QB = 'QB';
export const RB = 'RB';
export const WR = 'WR';
export const TE = 'TE';
export const K = 'K';
export const DST = 'DST';
export const CPT = 'CPT';
export const FLEX = 'FLEX';
export const FLEX_POSITIONS = [ RB, WR, TE ];
