import { LogOutLink, useMember } from 'app/auth';
import appText from 'app/text.json';

const Header = () => {
  const member = useMember();
  return (
    <header className="container-fluid">
      <div className="row py-2 bg-primary text-light align-items-center">
        <div className="col px-4 py-2">
          <span className="fs-4">{appText.appDisplayName}</span>
        </div>
        <div className="col px-4 py-2 text-end">
          <span>{ member.authorized ? <LogOutLink /> : '' }</span>
        </div>
      </div>
    </header>
  );
};

export default Header;
