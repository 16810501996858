import { useCallback } from 'react';
import { Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import { useSlate, useSlateDispatch, useWeek, useWeekDispatch } from './SlateViewContext';

const SlateSelector = ({ weeks, slates }) => {

  const week = useWeek();
  const slate = useSlate();

  return (
    <div>
      <div className="d-inline-block me-5">
        <div>Week:</div>
        <DropdownButton title={week ? week : 'Select Week'}>
          { !weeks ? <Loading /> : weeks.map(week => <WeekOption key={week} week={week} />) }
        </DropdownButton>
      </div>
      <div className="d-inline-block me-5">
        <div>Slate:</div>
        <DropdownButton title={slate ? slate.name : 'Select Slate'}>
          { !slates ? <Loading /> : slates.map(slate => <SlateOption key={slate.id} week={week} slate={slate} />) }
        </DropdownButton>
      </div>
    </div>
  );
};

const Loading = () => {
  return (
    <Dropdown.Item>
      <span className="me-5">Loading...</span>
      <Spinner animation="border" variant="secondary" size="sm" role="status" />
    </Dropdown.Item>
  );
};

const WeekOption = ({ week }) => {
  const dispatchWeek = useWeekDispatch();
  const onClick = useCallback(() => dispatchWeek(week), [ dispatchWeek, week ]);
  return <Dropdown.Item onClick={onClick}>{week}</Dropdown.Item>;
};

const SlateOption = ({ week, slate }) => {
  const dispatchSlate = useSlateDispatch();
  const onClick = useCallback(() => dispatchSlate(week, slate), [ dispatchSlate, slate, week ]);
  return <Dropdown.Item onClick={onClick}>{slate.name}</Dropdown.Item>;
};

export default SlateSelector;
