import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLineups } from './redux';
import { useSlate } from 'app/slates';
import { epochTime, exportFile } from 'app/util';
import Analysis from './Analysis';
import Lineups from './Lineups';

const toCsv = lineups => {
  if (!lineups.length) {
    return '';
  }
  const positions = lineups[0].positions();
  const header = Object.values(positions).join(',');
  const rows = lineups.map(lineup => {
    return Object.keys(positions)
      .map(position => lineup.get(position).vendorId)
      .join(',');
  });
  return [ header, ...rows ].join('\n');
};

const LineupsView = () => {

  const slate = useSlate();
  const lineups = useLineups(slate);

  const buttonClassNames = 'btn btn-outline-dark d-inline-block me-3';

  const navigateTo = useNavigate();
  const navigateToCreate = useCallback(() => navigateTo('/lineups/create'), [ navigateTo ]);

  const exportCsv = useCallback(() => {
    const content = toCsv(lineups);
    const contentType = 'text/csv';
    const fileName = `lineups-${epochTime()}.csv`;
    exportFile(content, contentType, fileName);
  }, [ lineups ]);

  return (
    <>
      <div className="mb-4 text-end">
        <button type="button" className={buttonClassNames} onClick={navigateToCreate}>Create Lineup</button>
        <button type="button" className={buttonClassNames} onClick={exportCsv} disabled={!lineups.length}>Export CSV</button>
      </div>
      <div className="row">
        <div className="col-5">
          <Lineups lineups={lineups} />
        </div>
        <div className="col-7">
          <Analysis slate={slate} lineups={lineups} />
        </div>
      </div>
    </>
  );
};

export default LineupsView;
