import { useMember } from 'app/auth';
import Authorized from './Authorized';
import Public from './Public';

const Body = () => {
  const member = useMember();
  return (
    <div className="container-fluid flex-grow-1">
      <div className="row h-100">
        { member.authorized ? <Authorized /> : <Public /> }
      </div>
    </div>
  );
};

export default Body;
