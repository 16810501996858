import { combineReducers } from 'redux';
import { get } from 'app/util/rest-client';


/**
 * api
 */
const FETCHING = 'FETCHING';
const _fetching = request => ({
  type: FETCHING,
  request
});

export const FETCH_FAILED = 'FETCH_FAILED';
const _fetchFailed = (request, status) => ({
  type: FETCH_FAILED,
  request,
  status
});

const API_RESPONSE = 'API_RESPONSE';
const _response = (request, response) => ({
  type: API_RESPONSE,
  request,
  response
});

const CLEAR_DATA = 'CLEAR_DATA';
const _clearData = () => ({
  type: CLEAR_DATA
});


const fetching = (state = {}, action) => {
  if (action.type === FETCHING) {
    return { ...state, [action.request.path]: true };
  } else if ([ API_RESPONSE, FETCH_FAILED ].includes(action.type)) {
    return { ...state, [action.request.path]: false };
  } else {
    return state;
  }
};

const received = (state = {}, action) => {
  if (action.type === FETCHING) {
    return { ...state, [action.request.path]: false };
  } else if (action.type === API_RESPONSE) {
    return { ...state, [action.request.path]: true };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

export const api = combineReducers({ fetching, received });


const _get = request => {
  return (dispatch, getState) => {
    if (!request.path) {
      return;
    }
    const api = getState().api;
    if (api.fetching[request.path] || api.received[request.path]) {
      return;
    }
    dispatch(_fetching(request));
    get(request.path)
      .then(response => response.json())
      .then(json => dispatch(_response(request, json)))
      .catch(e => dispatch(_fetchFailed(request, e.status)));
  };
};

export const getWeeks = () => (() => _get({
  type: 'weeks',
  path: '/api/weeks'
}))();

export const getData = week => (() => _get({
  type: 'data',
  path: week && `/api/weeks/${week}`
}))();

export const resetApiData = () => {
  return dispatch => {
    dispatch(_clearData());
    dispatch(getWeeks());
  };
};

const isDataRequestResponse = action => action.type === API_RESPONSE && action.request.type === 'data';

/**
 * games
 */
export const games = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: action.response.games };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};


/**
 * injuries
 */
const SORT_INJURIES = 'SORT_INJURIES';
export const sortInjuries = (datasetId, field) => ({
  type: SORT_INJURIES,
  datasetId,
  field
});

export const injuries = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: { data: action.response.injuries } };
  } else if (action.type === SORT_INJURIES) {
    return { ...state, [action.datasetId]: { ...state[action.datasetId], sortField: action.field } };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};


/**
 * matchups
 */
const SORT_MATCHUPS = 'SORT_MATCHUPS';
export const sortMatchups = (datasetId, field) => ({
  type: SORT_MATCHUPS,
  datasetId,
  field
});

export const matchups = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: { data: _receivedMatchups(state, action) } };
  } else if (action.type === SORT_MATCHUPS) {
    return { ...state, [action.datasetId]: { ...state[action.datasetId], sortField: action.field } };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const _receivedMatchups = (state, action) => {
  const teams = action.response.teams[action.response.week];
  return action.response.games.flatMap(game => {
    return [
      {
        id: `${game.home}_${game.away}`,
        team: game.home,
        def: game.away,
        opg: teams[game.home].offense.pointsPerGame,
        dpg: teams[game.away].defense.pointsPerGame,
        apg: (teams[game.home].offense.pointsPerGame + teams[game.away].defense.pointsPerGame) / 2,
        oryg: teams[game.home].offense.rushYdsPerGame,
        dryg: teams[game.away].defense.rushYdsPerGame,
        aryg: (teams[game.home].offense.rushYdsPerGame + teams[game.away].defense.rushYdsPerGame) / 2,
        opyg: teams[game.home].offense.passYdsPerGame,
        dpyg: teams[game.away].defense.passYdsPerGame,
        apyg: (teams[game.home].offense.passYdsPerGame + teams[game.away].defense.passYdsPerGame) / 2,
        osg: teams[game.home].offense.sacksAllowed,
        dsg: teams[game.away].defense.sacks,
        asg: (teams[game.home].offense.sacksAllowed + teams[game.away].defense.sacks) / 2,
        otg: teams[game.home].offense.turnovers,
        dtg: teams[game.away].defense.turnovers,
        atg: (teams[game.home].offense.turnovers + teams[game.away].defense.turnovers) / 2
      },
      {
        id: `${game.away}_${game.home}`,
        team: game.away,
        def: game.home,
        opg: teams[game.away].offense.pointsPerGame,
        dpg: teams[game.home].defense.pointsPerGame,
        apg: (teams[game.away].offense.pointsPerGame + teams[game.home].defense.pointsPerGame) / 2,
        oryg: teams[game.away].offense.rushYdsPerGame,
        dryg: teams[game.home].defense.rushYdsPerGame,
        aryg: (teams[game.away].offense.rushYdsPerGame + teams[game.home].defense.rushYdsPerGame) / 2,
        opyg: teams[game.away].offense.passYdsPerGame,
        dpyg: teams[game.home].defense.passYdsPerGame,
        apyg: (teams[game.away].offense.passYdsPerGame + teams[game.home].defense.passYdsPerGame) / 2,
        osg: teams[game.away].offense.sacksAllowed,
        dsg: teams[game.home].defense.sacks,
        asg: (teams[game.away].offense.sacksAllowed + teams[game.home].defense.sacks) / 2,
        otg: teams[game.away].offense.turnovers,
        dtg: teams[game.home].defense.turnovers,
        atg: (teams[game.away].offense.turnovers + teams[game.home].defense.turnovers) / 2
      }
    ];
  });
};


/**
 * players
 */
const PUT_CUSTOM = 'PUT_CUSTOM';
export const putCustom = (slate, player, key, value) => ({
  type: PUT_CUSTOM,
  slate,
  player,
  key,
  value
});

const SET_INTEREST = 'SET_INTEREST';
export const setInterest = (slateId, player, interest) => ({
  type: SET_INTEREST,
  slateId,
  player,
  interest
});

const SORT_PLAYER_STATS = 'SORT_PLAYER_STATS';
export const sortPlayerStats = (datasetId, field) => ({
  type: SORT_PLAYER_STATS,
  datasetId,
  field
});

const SORT_SLATE_PLAYERS = 'SORT_SLATE_PLAYERS';
export const sortSlatePlayers = (datasetId, field) => ({
  type: SORT_SLATE_PLAYERS,
  datasetId,
  field
});


const playersByWeek = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: action.response.players[action.response.week] };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const playerGames = (state = {}, action) => {
  if (isDataRequestResponse(action) && (!state.week || state.week < action.response.week)) {
    return { ...action.response.players.games, week: action.response.week };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const playerSlates = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, ..._receivedPlayerSlates(action) };
  } else if (action.type === SORT_SLATE_PLAYERS) {
    return { ...state, [action.datasetId]: { ...state[action.datasetId], sortField: action.field } };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const _receivedPlayerSlates = action => Object.entries(action.response.players.slates)
  .reduce((result, [ k, v ]) => ({ ...result, [k]: { data: v } }), {});

const playerStats = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: { data: action.response.players.stats } };
  } else if (action.type === SORT_PLAYER_STATS) {
    return { ...state, [action.datasetId]: { ...state[action.datasetId], sortField: action.field } };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const custom = (state = {}, action) => {
  if (action.type === PUT_CUSTOM) {
    return { ...state, [action.slate.id]: _newCustom(state, action) };
  } else {
    return state;
  }
};

const _newCustom = (state, action) => {
  const slate = { ...state[action.slate.id] };
  const playerId = action.player.vendorId;
  const value = Number(action.value);
  if (value) {
    slate[playerId] = { ...slate[playerId], [action.key]: value };
  } else {
    delete slate[playerId]?.[action.key];
  }
  return slate;
};

const interests = (state = {}, action) => {
  if (action.type === SET_INTEREST) {
    return { ...state, [action.slateId]: { ...state[action.slateId], [action.player.vendorId]: action.interest } };
  } else {
    return state;
  }
};

export const players = combineReducers({
  byWeek: playersByWeek,
  slates: playerSlates,
  games: playerGames,
  stats: playerStats,
  interests,
  custom
});


/**
 * pvt
 */
export const pvt = (state = {}, action) => {
  if (isDataRequestResponse(action) && (!state.week || state.week < action.response.week)) {
    return { ...action.response.pvt, week: action.response.week };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};


/**
 * slates
 */
export const slates = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: action.response.slates };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};


/**
 * teams
 */
const SORT_OFFENSE_STATS = 'SORT_OFFENSE_STATS';
export const sortOffenseStats = (datasetId, field) => ({
  type: SORT_OFFENSE_STATS,
  datasetId,
  field
});

const SORT_DEFENSE_STATS = 'SORT_DEFENSE_STATS';
export const sortDefenseStats = (datasetId, field) => ({
  type: SORT_DEFENSE_STATS,
  datasetId,
  field
});


const teamsByWeek = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: action.response.teams[action.response.week] };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const teamGames = (state = {}, action) => {
  if (isDataRequestResponse(action) && (!state.week || state.week < action.response.week)) {
    return { ...action.response.teams.games, week: action.response.week };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const offense = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: { data: action.response.teams.stats.offense } };
  } else if (action.type === SORT_OFFENSE_STATS) {
    return { ...state, [action.datasetId]: { ...state[action.datasetId], sortField: action.field } };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const defense = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: { data: action.response.teams.stats.defense } };
  } else if (action.type === SORT_DEFENSE_STATS) {
    return { ...state, [action.datasetId]: { ...state[action.datasetId], sortField: action.field } };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

export const teams = combineReducers({
  byWeek: teamsByWeek,
  games: teamGames,
  stats: combineReducers({ offense, defense })
});


/**
 * weeks
 *
 * weeks.values defaults to null as compared to an empty array to clarify the distinction between
 * weeks having not been loaded from the server (i.e., null) vs. the server responding with an empty
 * array of weeks.values
 */
export const weeks = (state = null, action) => {
  if (action.type === API_RESPONSE && action.request.type === 'weeks') {
    return action.response;
  } else if (action.type === CLEAR_DATA) {
    return null;
  } else {
    return state;
  }
};
