import { Modal } from 'react-bootstrap';
import { useSelectedTeam, useShowTeamModal, useShowTeamModalDispatch } from './TeamModalContext';
import { useDefenseGameFields, useOffenseGameFields } from '../fields';
import { useGames } from '../redux';
import { DataTable } from 'app/components';
import { useWeek } from 'app/slates/SlateViewContext';
import { useCallback } from 'react';

const Header = () => {
  return (
    <div className="container">
      <div className="row">
        <Identification />
        <Miscellany />
      </div>
    </div>
  );
};

const Identification = () => {
  const team = useSelectedTeam();
  return (
    <div className="col-5">
      <div className="row">
        <div className="col">
          <h2>{team.name} {team.unit}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h5>{team.versus}</h5>
        </div>
      </div>
    </div>
  );
};

const Miscellany = () => {
  return (
    <div className="col-3">
      <div className="row">
        <table>
          <tbody>
            <tr />
          </tbody>
        </table>
      </div>
    </div>
  );
};

const OffenseGameLogs = () => {

  const week = useWeek();
  const team = useSelectedTeam();
  const games = useGames(team.name, week);
  const dataset = {
    data: games.map(g => g['offense']),
    fields: useOffenseGameFields()
  };

  return <GameLogs dataset={dataset} />;
};

const DefenseGameLogs = () => {

  const week = useWeek();
  const team = useSelectedTeam();
  const games = useGames(team.name, week);
  const dataset = {
    data: games.map(g => g['defense']),
    fields: useDefenseGameFields()
  };

  return <GameLogs dataset={dataset} />;
};

const GameLogs = ({ dataset }) => {

  const tableConfig = {
    sorting: false,
    classNames: 'table-sm'
  };

  return <DataTable dataset={dataset} config={tableConfig} />;
};

const TeamModal = () => {

  const team = useSelectedTeam();
  const show = useShowTeamModal();

  const dispatchShowTeamModal = useShowTeamModalDispatch();
  const onHide = useCallback(() => dispatchShowTeamModal(false), [ dispatchShowTeamModal ]);

  return (
    <Modal
      centered
      show={show}
      animation={false}
      onHide={onHide}
      dialogClassName="data-modal"
      aria-labelledby="data-modal-title"
    >
      <Modal.Header closeButton>
        <Header />
      </Modal.Header>
      <Modal.Body>
        {
          team.unit.toLowerCase() === 'offense'
            ? <OffenseGameLogs />
            : <DefenseGameLogs />
        }
      </Modal.Body>
    </Modal>
  );
};

const TeamModalWrapper = () => {
  return !!useSelectedTeam() && <TeamModal />;
};

export default TeamModalWrapper;
