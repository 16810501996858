import { createElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSlates } from './redux';
import { SlateViewProvider, useWeek } from './SlateViewContext';
import { getData, getWeeks } from 'app/api';
import { LoadingGif } from 'app/components';
import { PlayerModalProvider } from 'app/players';
import { TeamModalProvider } from 'app/teams';
import { useIsView } from 'app/view';
import { GAMES, LINEUPS } from 'app/views';
import { useWeeks } from 'app/weeks/redux';
import RefreshButton from './RefreshButton';
import SlateSelector from './SlateSelector';
import SlateTeamsFilter from './SlateTeamsFilter';

const SlateView = ({ header, component }) => {

  const dispatch = useDispatch();

  useEffect(() => dispatch(getWeeks()), [ dispatch ]);
  const weeks = useWeeks();

  const week = useWeek();
  useEffect(() => dispatch(getData(week)), [ dispatch, week ]);
  const slates = useSlates(week);

  const isNotGamesView = !useIsView(GAMES);
  const isNotLineupsView = !useIsView(LINEUPS);

  return (
    <div className="h-100">
      <h2 className="mb-4">
        { header }
      </h2>
      <div className="d-flex justify-content-between me-3 mb-4">
        <SlateSelector weeks={weeks} slates={slates} />
        <RefreshButton />
      </div>
      { !!slates && !!isNotGamesView && !!isNotLineupsView && <SlateTeamsFilter /> }
      { slates ? createElement(component) : <LoadingGif /> }
    </div>
  );
};

const SlateViewWithContext = ({ header, component }) => {
  return (
    <SlateViewProvider>
      <TeamModalProvider>
        <PlayerModalProvider>
          <SlateView header={header} component={component} />
        </PlayerModalProvider>
      </TeamModalProvider>
    </SlateViewProvider>
  );
};

export default SlateViewWithContext;
