import { useSelector } from 'react-redux';
import { useMultiPositionFilterData } from './MultiPositonFilter';
import { usePlayerStatsFields } from '../fields';
import { sortPlayerStats } from 'app/api';
import { usePlayerStatsFieldGroup, useSlateTeamsFilter } from 'app/slates';
import { sort, sortedFields } from 'app/sorting';


/*
 * hooks
 */
export const usePlayerStatsDataset = week => {
  const stats = useSelector(({ players }) => players.stats[week]);
  const players = useSelector(({ players }) => players.byWeek[week]);
  const data = stats.data.map(pid => ({ ...players[pid] }));
  return {
    id: week,
    data: stats.sortField ? sort(data, stats.sortField) : data,
    fields: sortedFields(usePlayerStatsFields(usePlayerStatsFieldGroup()), stats.sortField),
    sort: sortPlayerStats
  };
};

export const usePlayerStatsFilters = slate => {
  const positions = Object.entries(useMultiPositionFilterData(slate))
    .filter(([ _, v ]) => v)
    .map(([ k ]) => k);
  return [
    { evaluate: player => positions.length === 0 || positions.includes(player.position) },
    ...useSlateTeamsFilter(slate)
  ];
};
