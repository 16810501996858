import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteLineup } from './redux';
import { useSlate } from 'app/slates';
import LineupSummary from './LineupSummary';

const Lineups = ({ lineups }) => {

  const slate = useSlate();

  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const _edit = lineup => () => navigateTo(`/lineups/edit/${lineup.id()}`);
  const _delete = lineup => () => dispatch(deleteLineup(slate.id, lineup.id()));

  return (
    <>
      <div className="row px-1 mb-2">
        <h4>Lineups</h4>
      </div>
      <div className="row">
        <div className="col">
          {
            lineups.map(lineup => (
              <LineupSummary
                key={lineup.id()}
                lineup={lineup}
                _edit={_edit(lineup)}
                _delete={_delete(lineup)}
              />))
          }
        </div>
      </div>
    </>
  );
};

export default Lineups;
