import { useSelector } from 'react-redux';

/*
 * actions
 */
const PUT_FILTER = 'PUT_FILTER';
export const putFilter = action => ({
  type: PUT_FILTER,
  ...action
});

const CLEAR_FILTER = 'CLEAR_FILTER';
export const clearFilter = (slateId, filterType) => ({
  type: CLEAR_FILTER,
  slateId,
  filterType
});

/*
 * reducer
 */
const filters = (state = {}, action) => {
  if (action.type === PUT_FILTER) {
    return _putFilter(state, action);
  } else if (action.type === CLEAR_FILTER) {
    return _clearFilter(state, action);
  } else {
    return state;
  }
};

const _putFilter = (state, action) => {
  return { ...state, [action.slateId]: { ...state[action.slateId], ...action.filter } };
};

const _clearFilter = (state, action) => {
  const { [action.filterType]: _, ...remainingFilters } = state[action.slateId];
  return { ...state, [action.slateId]: remainingFilters };
};

export default filters;

/*
 * hooks
 */
export const useRegisteredFilters = (slate, types, defaults = {}) => {
  const registered = useSelector(({ filters }) => filters[slate.id]) || {};
  return Object.entries({ ...defaults, ...registered })
    .filter(([ type ]) => types.includes(type));
};

export const useFilterData = (slate, type) => {
  return useSelector(({ filters }) => filters[slate.id]?.[type]);
};
