import { useCallback } from 'react';
import { useHomeAwayDispatch, useHomeOrAway, useSelectedGame } from './GamesViewContext';

const HOME = 'home';
const AWAY = 'away';
const BOTH = 'both';

export const useHomeAwayFilter = slate => {
  const game = useSelectedGame(slate);
  const homeOrAway = useHomeOrAway(slate) || BOTH;
  const teams = homeOrAway === BOTH ? [ game.away, game.home ] : homeOrAway === AWAY ? [ game.away ] : [ game.home ];
  return { evaluate: player => teams.includes(player.team) };
};

const HomeAwayFilter = ({ slate, tab }) => {

  const dispatch = useHomeAwayDispatch();
  const homeOrAway = useHomeOrAway(slate) || BOTH;
  const changeHandler = useCallback(e => dispatch(slate, e.target.value), [ dispatch, slate ]);

  return (
    <div className="d-inline me-5">
      <div className="form-check-right form-check-inline me-4">
        <input
          type="radio"
          checked={homeOrAway === AWAY}
          className="form-check-input-right pointer"
          id={`home-away-radio-away-${tab}`}
          name={`home-away-radio-${tab}`}
          onChange={changeHandler}
          value={AWAY}
        />
        <label className="form-check-label pointer" htmlFor={`home-away-radio-away-${tab}`}>Away</label>
      </div>
      <div className="form-check-right form-check-inline me-4">
        <input
          type="radio"
          checked={homeOrAway === HOME}
          className="form-check-input-right pointer"
          id={`home-away-radio-home-${tab}`}
          name={`home-away-radio-${tab}`}
          onChange={changeHandler}
          value={HOME}
        />
        <label className="form-check-label pointer" htmlFor={`home-away-radio-home-${tab}`}>Home</label>
      </div>
      <div className="form-check-right form-check-inline">
        <input
          type="radio"
          checked={homeOrAway === BOTH}
          className="form-check-input-right pointer"
          id={`home-away-radio-all-${tab}`}
          name={`home-away-radio-${tab}`}
          onChange={changeHandler}
          value={BOTH}
        />
        <label className="form-check-label pointer" htmlFor={`home-away-radio-all-${tab}`}>Both</label>
      </div>
    </div>
  );
};

export default HomeAwayFilter;
