import { TextInputFilter } from 'app/components';
import { putMinSnapPercentageFilter, useMinSnapPercentageFilterData } from './redux';

const MinSnapPercentageFilter = ({ slate }) => {

  const percentage = useMinSnapPercentageFilterData(slate);

  return (
    <TextInputFilter
      id="min-snap-filter"
      label="Min. Snap %"
      putFilter={putMinSnapPercentageFilter}
      slate={slate}
      value={percentage}
    />
  );
};

export default MinSnapPercentageFilter;
