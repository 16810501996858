import { useDefenseStatsDataset } from './redux';
import { useSlate } from 'app/slates';
import TeamStats from './TeamStats';

const DefensesView = () => {

  const slate = useSlate();
  const dataset = useDefenseStatsDataset(slate.week);

  return <TeamStats slate={slate} unit="Defense" dataset={dataset} />;
};

export default DefensesView;
