import { Tab, Tabs } from 'react-bootstrap';
import { playerStatsFieldGroups } from '../fields';
import { usePlayerStatsFieldGroup, usePlayerStatsFieldGroupDispatch } from 'app/slates';

const PlayerStatsFieldGroupSelector = () => {

  return (
    <Tabs activeKey={usePlayerStatsFieldGroup()} onSelect={usePlayerStatsFieldGroupDispatch()}>
      { playerStatsFieldGroups().map(fg => <Tab key={fg.id} eventKey={fg.id} title={fg.text} />) }
    </Tabs>
  );
};

export default PlayerStatsFieldGroupSelector;
