import { OptimizerLineup } from './OptimizerLineup';
import { Lineups } from './Lineups';
import { PlayerPool } from './PlayerPool';
import { CLASSIC, CPT, DST, FLEX, QB, RB, SHOWDOWN, TE, WR } from 'app/constants';
import { SALARY_CAP } from 'app/lineups/constants';

const CLASSIC_CONFIG = {
  positions: [ QB, RB, RB, WR, WR, WR, TE, FLEX, DST ],
  flexPositions: [ RB, WR, TE ],
  salaryCap: SALARY_CAP
};

const SHOWDOWN_CONFIG = {
  positions: [ CPT, FLEX, FLEX, FLEX, FLEX, FLEX ],
  flexPositions: [ FLEX ],
  salaryCap: SALARY_CAP
};

const optimizerSalary = salary => salary / 100;

const makeLineupFactory = (positions, salaryCap) => ({
  makeLineup: players => new OptimizerLineup(positions, salaryCap, players)
});

const optimizer = ({ positions, flexPositions, salaryCap }) => {

  const POSITIONS = [ ...positions ];

  const lineupFactory = makeLineupFactory(positions, salaryCap);

  /*
   * matrix must be buffered in both dimensions
   */
  const ROW_COUNT = positions.length + 1;
  const COLUMN_COUNT = optimizerSalary(salaryCap) + 1;

  /*
   * The lowest salary possible based on the current row and the set of players.
   * Used to avoid processing that has no chance of producing viable lineups.
   *
   * (Actually, as it doesn't account for possibly counting the same player twice,
   *  this can be even lower than what is possible, but it's good enough.)
   */
  let minSalary;

  /*
   * A matrix of lineups populated via a dynamic programming approach to solving
   * for an optimal lineup; i.e., a common "knapsack problem" solution.
   */
  let lineups;

  let playerPool;

  const optimalPlayer = (row, salary, players) => {
    const possible = possiblePlayers(row, salary, players);
    if (!possible.length) {
      return false;
    }
    return possible.reduce((optimal, current) => {
      return !optimal || previous(row, salary, current).potentialPoints(current) > previous(row, salary, optimal).potentialPoints(optimal) ? current : optimal;
    });
  };

  const possiblePlayers = (row, salary, players) => {
    return players
      .filter(player => optimizerSalary(player.salary) <= salary)
      .filter(player => previous(row, salary, player).playerCount() === row - 1)
      .filter(player => previous(row, salary, player).isFeasible(player));
  };

  const previous = (row, salary, player) => lineups.previous(row, salary - optimizerSalary(player.salary));

  const optimalLineup = () => {

    for (let row = 1; row < ROW_COUNT; row++) {

      minSalary += optimizerSalary(playerPool.minSalary(POSITIONS[row - 1]));

      const players = playerPool.playersForPosition(POSITIONS[row - 1]);

      for (let salary = 0; salary < COLUMN_COUNT; salary++) {

        if (salary < minSalary) {
          continue;
        }

        const optimal = optimalPlayer(row, salary, players);
        if (optimal) {
          lineups.add(row, salary, previous(row, salary, optimal).add(optimal));
        } else {
          lineups.add(row, salary, lineupFactory.makeLineup());
        }
      }
    }

    return lineups.optimal();
  };

  return {
    generate: (options, locks) => {
      minSalary = 0;
      lineups = new Lineups(lineupFactory);
      playerPool = new PlayerPool({ options, locks, flexPositions });
      return Promise.resolve(optimalLineup());
    }
  };
};

const wrapper = {
  for: contestType => {
    switch (contestType) {
      case CLASSIC:
        return optimizer(CLASSIC_CONFIG);
      case SHOWDOWN:
        return optimizer(SHOWDOWN_CONFIG);
      default:
        throw new Error(`unrecognized contest type: ${contestType}`);
    }
  }
};

export default wrapper;
