import { useSelector } from 'react-redux';
import { sortMatchups } from 'app/api';
import { sort, sortedFields } from 'app/sorting';
import useMatchupsFields from './fields';

/*
 * hooks
 */
export const useMatchupsDataset = week => {
  const matchups = useSelector(({ matchups }) => matchups[week]);
  return {
    id: week,
    data: matchups.sortField ? sort(matchups.data, matchups.sortField) : matchups.data,
    fields: sortedFields(useMatchupsFields(), matchups.sortField),
    sort: sortMatchups
  };
};
