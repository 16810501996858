import { Tab, Tabs } from 'react-bootstrap';
import { useSelectedGame } from './GamesViewContext';
import { useSlate } from 'app/slates';
import { useTeams } from 'app/teams';
import DepthChartsTab from './DepthChartsTab';
import InjuryReportTab from './InjuryReportTab';
import InjuriesSummaryTab from './InjuriesSummaryTab';
import MatchupTab from './MatchupTab';
import PlayersTab from './PlayersTab';

const GamesViewTabs = () => {

  const slate = useSlate();
  const game = useSelectedGame(slate);
  const teams = useTeams(slate.week);

  return (
    <div className="my-4">
      <div className="mb-4">
        <h4>{`${game.away} (${teams[game.away].record}) @ ${game.home} (${teams[game.home].record})`}</h4>
      </div>
      <Tabs defaultActiveKey="matchup">
        <Tab eventKey="matchup" title="Matchup">
          <MatchupTab week={slate.week} game={game} />
        </Tab>
        <Tab eventKey="players" title="Players">
          <PlayersTab />
        </Tab>
        <Tab eventKey="injuriesSummary" title="Injury Summary">
          <InjuriesSummaryTab week={slate.week} game={game} />
        </Tab>
        <Tab eventKey="injuryReport" title="Injury Report">
          <InjuryReportTab />
        </Tab>
        <Tab eventKey="depthCharts" title="Depth Charts">
          <DepthChartsTab week={slate.week} away={game.away} home={game.home} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default GamesViewTabs;
