import { GamesViewProvider } from './GamesViewContext';
import GameSelector from './GameSelector';
import GamesViewTabs from './GamesViewTabs';

const GamesView = () => {

  return (
    <GamesViewProvider>
      <GameSelector />
      <GamesViewTabs />
    </GamesViewProvider>
  );
};

export default GamesView;
