import { DST, FLEX, QB, RB, TE, WR } from 'app/constants';
import { CLASSIC_POSITIONS, RB1, RB2, WR1, WR2, WR3 } from '../constants';
import Lineup from './Lineup';

const ClassicLineup = class extends Lineup {

  constructor (lineup = {}) {
    super(lineup);
    this._positions = CLASSIC_POSITIONS;
  }

  hasOpen (position) {
    if ([ QB, DST ].includes(position)) {
      return !this._players[position];
    } else if (position === RB) {
      return !this._players[RB1] || !this._players[RB2] || !this._players[FLEX];
    } else if (position === WR) {
      return !this._players[WR1] || !this._players[WR2] || !this._players[WR3] || !this._players[FLEX];
    } else if (position === TE) {
      return !this._players[TE] || !this._players[FLEX];
    } else {
      return false;
    }
  }

  put (player) {
    if ([ QB, DST ].includes(player.slatePosition)) {
      this._players[player.slatePosition] = player;
    } else if (player.slatePosition === RB) {
      this._putRb(player);
    } else if (player.slatePosition === WR) {
      this._putWr(player);
    } else if (player.slatePosition === TE) {
      this._putTe(player);
    } else {
      throw new Error(`attempting to put player w/ invalid slatePosition: ${player.slatePosition}`);
    }
  }

  _putRb (player) {
    if (!this._players[RB1]) {
      this._players[RB1] = player;
    } else if (!this._players[RB2]) {
      this._players[RB2] = player;
    } else if (!this._players[FLEX]) {
      this._players[FLEX] = player;
    }
  }

  _putWr (player) {
    if (!this._players[WR1]) {
      this._players[WR1] = player;
    } else if (!this._players[WR2]) {
      this._players[WR2] = player;
    } else if (!this._players[WR3]) {
      this._players[WR3] = player;
    } else if (!this._players[FLEX]) {
      this._players[FLEX] = player;
    }
  }

  _putTe (player) {
    if (!this._players[TE]) {
      this._players[TE] = player;
    } else if (!this._players[FLEX]) {
      this._players[FLEX] = player;
    }
  }
};

export default ClassicLineup;
