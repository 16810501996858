import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { putShowdownPositionFilter, useShowdownPositionFilterData } from './redux';
import { CPT, FLEX, SHOWDOWN } from 'app/constants';

const ShowdownPositionFilter = ({ slate }) => {

  const dispatch = useDispatch();
  const position = useShowdownPositionFilterData(slate);
  const changeHandler = useCallback(e => dispatch(putShowdownPositionFilter(slate.id, e.target.value)), [ dispatch, slate ]);

  return (
    <div className="d-inline-block me-5">
      <div className="form-check-right form-check-inline">
        <input
          type="radio"
          checked={position === CPT}
          className="form-check-input-right pointer"
          id="showdown-position-radio-cpt"
          name="showdown-position-radio"
          onChange={changeHandler}
          value={CPT}
        />
        <label className="form-check-label pointer" htmlFor="showdown-position-radio-cpt">Captain</label>
      </div>
      <div className="form-check-right form-check-inline me-0">
        <input
          type="radio"
          checked={position === FLEX}
          className="form-check-input-right pointer"
          id="showdown-position-radio-flex"
          name="showdown-position-radio"
          onChange={changeHandler}
          value={FLEX}
        />
        <label className="form-check-label pointer" htmlFor="showdown-position-radio-flex">Flex</label>
      </div>
    </div>
  );
};

const ShowdownPositionFilterWrapper = ({ slate }) => {
  return slate.contestType === SHOWDOWN && <ShowdownPositionFilter slate={slate} />;
};

export default ShowdownPositionFilterWrapper;
