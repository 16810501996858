import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useClearSelectedWeek } from './SlateViewContext';
import { resetApiData } from 'app/api';

const RefreshButton = () => {

  const dispatch = useDispatch();
  const clearSelectedWeek = useClearSelectedWeek();

  const refreshData = useCallback(() => {
    clearSelectedWeek();
    dispatch(resetApiData());
  }, [ clearSelectedWeek, dispatch ]);

  return (
    <div>
      <div>Refresh:</div>
      <div>
        <button
          type="button"
          className="btn btn-outline-dark"
          onClick={refreshData}
        >
          Data
        </button>
      </div>
    </div>
  );
};

export default RefreshButton;
