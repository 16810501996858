import { usePlayerStatsDataset, usePlayerStatsFilters } from './redux';
import { DataTable } from 'app/components';
import { useSlate } from 'app/slates';
import MultiPositionFilter from './MultiPositonFilter';
import PlayerStatsFieldGroupSelector from './PlayerStatsFieldGroupSelector';

const PlayerStatsView = () => {

  const slate = useSlate();
  const dataset = usePlayerStatsDataset(slate.week);
  const filters = usePlayerStatsFilters(slate);

  return (
    <div className="my-4">
      <div className="mb-3">
        <div className="ms-2 mb-3">
          <MultiPositionFilter />
        </div>
      </div>
      <div>
        <PlayerStatsFieldGroupSelector />
      </div>
      <div>
        <DataTable dataset={dataset} filters={filters} />
      </div>
    </div>
  );
};

export default PlayerStatsView;
