const upToTwoDecimals = data => Math.floor(data * 100) / 100;

const useMatchupsFields = () => [
  {
    name: 'team',
    header: 'off',
    order: 1,
    orderedBy: 0
  },
  {
    name: 'def',
    header: 'def',
    order: 1,
    orderedBy: 0
  },
  {
    name: 'opg',
    header: 'opg',
    order: -1,
    orderedBy: 0
  },
  {
    name: 'dpg',
    header: 'dpg',
    order: -1,
    orderedBy: 0
  },
  {
    name: 'apg',
    type: 'custom',
    header: 'apg',
    order: -1,
    orderedBy: 0,
    render: team => upToTwoDecimals(team['apg'])
  },
  {
    name: 'oryg',
    header: 'oryg',
    order: -1,
    orderedBy: 0
  },
  {
    name: 'dryg',
    header: 'dryg',
    order: -1,
    orderedBy: 0
  },
  {
    name: 'aryg',
    header: 'aryg',
    type: 'custom',
    order: -1,
    orderedBy: 0,
    render: team => upToTwoDecimals(team['aryg'])
  },
  {
    name: 'opyg',
    header: 'opyg',
    order: -1,
    orderedBy: 0
  },
  {
    name: 'dpyg',
    header: 'dpyg',
    order: -1,
    orderedBy: 0
  },
  {
    name: 'apyg',
    header: 'apyg',
    type: 'custom',
    order: -1,
    orderedBy: 0,
    render: team => upToTwoDecimals(team['apyg'])
  },
  {
    name: 'osg',
    header: 'osg',
    order: -1,
    orderedBy: 0
  },
  {
    name: 'dsg',
    header: 'dsg',
    order: -1,
    orderedBy: 0
  },
  {
    name: 'asg',
    header: 'asg',
    type: 'custom',
    order: -1,
    orderedBy: 0,
    render: team => upToTwoDecimals(team['asg'])
  },
  {
    name: 'otg',
    header: 'otg',
    order: -1,
    orderedBy: 0
  },
  {
    name: 'dtg',
    header: 'dtg',
    order: -1,
    orderedBy: 0
  },
  {
    name: 'atg',
    header: 'atg',
    order: -1,
    orderedBy: 0
  }
];

export default useMatchupsFields;
