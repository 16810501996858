import { useOffenseStatsDataset } from './redux';
import { useSlate } from 'app/slates';
import TeamStats from './TeamStats';

const OffensesView = () => {

  const slate = useSlate();
  const dataset = useOffenseStatsDataset(slate.week);

  return <TeamStats slate={slate} unit="Offense" dataset={dataset} />;
};

export default OffensesView;
