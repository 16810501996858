const NewsSearchLink = ({ subject }) => {

  const query = subject.replace(/\s+/, '+');

  return (
    <a
      href={`https://www.google.com/search?q=${query}&tbm=nws`}
      className="link"
      target="_blank"
      rel="noopener noreferrer"
    >
      search
    </a>
  );
};

export default NewsSearchLink;
