import { FilterToggleBox } from 'app/components';
import { clearFilter, putFilter, useFilterData } from 'app/filters';

export const POINTS = 'points';

const putPointsFilter = slateId => putFilter({
  slateId,
  filter: {
    [POINTS]: {}
  }
});

const clearPointsFilter = slateId => clearFilter(slateId, POINTS);

const usePointsFilterIsEnabled = slate => {
  return !!useFilterData(slate, POINTS);
};

const PointsFilter = ({ slate }) => {

  return (
    <FilterToggleBox
      label="Points"
      slate={slate}
      enabled={usePointsFilterIsEnabled(slate)}
      putFilter={putPointsFilter}
      clearFilter={clearPointsFilter}
    />
  );
};

export default PointsFilter;
