import { useSelector } from 'react-redux';
import { SLATE_TEAMS } from './SlateTeamsFilter';
import { useRegisteredFilters } from 'app/filters';

/*
 * hooks
 */
export const useSlates = week => useSelector(({ slates }) => slates)[week];

export const useSlateTeamsFilter = slate => {
  return useRegisteredFilters(slate, [ SLATE_TEAMS ])
    .map(([ _, fd ]) => ({
      evaluate: record => !fd.data.length
        || fd.data.includes(record.team)
        || fd.data.includes(record.name)
        || fd.data.includes(record.home)
        || fd.data.includes(record.away)
    }));
};
