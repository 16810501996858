import { FLEX } from 'app/constants';

const byPosition = players => {
  return players.reduce((byPosition, player) => {
    if (!byPosition[player.slatePosition]) {
      byPosition[player.slatePosition] = [ player ];
    } else {
      byPosition[player.slatePosition].push(player);
    }
    return byPosition;
  }, {});
};

export const PlayerPool = class {

  constructor ({ options, locks = [], flexPositions }) {
    this._options = byPosition(options);
    this._locks = byPosition(locks);
    this._flexPositions = flexPositions;
  }

  minSalary (position) {
    if (this._locks[position] && this._locks[position].length) {
      return Math.min(...this._locks[position].map(player => player.salary));
    } else {
      return Math.min(...this._optionsForPosition(position).map(player => player.salary));
    }
  }

  playersForPosition (position) {
    const lock = this._locks[position] && this._locks[position].length && this._locks[position].shift();
    if (lock) {
      return [ lock ];
    } else {
      return this._optionsForPosition(position);
    }
  }

  _optionsForPosition (position) {
    if (position === FLEX) {
      return this._flexPositions.flatMap(fp => this._options[fp]);
    } else {
      return [ ...this._options[position] ];
    }
  }
};
