import { useCallback } from 'react';

const Team = ({ team, isActive, clickHandler }) => {

  const teamClickHandler = useCallback(e => {
    e.stopPropagation();
    clickHandler(team);
  }, [ clickHandler, team ]);

  return <span onClick={teamClickHandler} className={isActive ? 'text-success' : ''}>{team}</span>;
};

const GameBox = ({ game, activeTeams, clickHandlers }) => {

  const gameClickHandler = useCallback(e => {
    e.stopPropagation();
    clickHandlers.game(game);
  }, [ clickHandlers, game ]);

  return (
    <div onClick={gameClickHandler} className="p-2 border-top border-bottom border-end pointer d-inline-block">
      <div>
        <Team team={game.away} isActive={activeTeams.includes(game.away)} clickHandler={clickHandlers.team} />
        <span> @</span>
      </div>
      <div>
        <Team team={game.home} isActive={activeTeams.includes(game.home)} clickHandler={clickHandlers.team} />
      </div>
      <div className="small">{game.day} {game.time}</div>
    </div>
  );
};

export default GameBox;
