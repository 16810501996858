import { useCallback } from 'react';
import { useDefense, useOffense, useSelectedTeamDispatch, useShowTeamModalDispatch } from 'app/teams';
import { secondsToMinutes } from 'app/util/calculations';

const ModalLink = ({ team }) => {

  const dispatchSelectedTeam = useSelectedTeamDispatch();
  const dispatchShowTeamModal = useShowTeamModalDispatch();

  const onClick = useCallback(() => {
    dispatchSelectedTeam(team);
    dispatchShowTeamModal(true);
  }, [ dispatchSelectedTeam, dispatchShowTeamModal, team ]);

  return <span className="link" onClick={onClick}>{`${team.name} ${team.unit.substring(0, 3)}`}</span>;
};

const MatchupTab = ({ week, game }) => {

  const away = {
    offense: { ...useOffense(game.away, week) },
    defense: { ...useDefense(game.away, week) }
  };

  const home = {
    offense: { ...useOffense(game.home, week) },
    defense: { ...useDefense(game.home, week) }
  };

  return (
    <div className="row my-4">
      <div className="col-7 border-end">
        <div className="px-3">
          <OffVsDefStats offense={away.offense} defense={home.defense} />
        </div>
        <div className="px-3">
          <OffVsDefStats offense={home.offense} defense={away.defense} />
        </div>
      </div>
      <div className="col-5">
        <div className="mb-5">
          <Vegas game={game} />
        </div>
        <div>
          <OffensePace away={away.offense} home={home.offense} />
        </div>
      </div>
    </div>
  );
};

const OffVsDefStats = ({ offense, defense }) => {
  return (
    <table className="table centered-columns table-borderless">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>pts/gm</th>
          <th>rushYds/gm</th>
          <th>rushTds</th>
          <th>passYds/gm</th>
          <th>passTds</th>
          <th>sacks</th>
          <th>turnovers</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>
            <ModalLink team={{ ...offense, unit: 'Offense' }} />
          </th>
          <td>{offense.pointsPerGame}</td>
          <td>{offense.rushYdsPerGame}</td>
          <td>{offense.rushTds}</td>
          <td>{offense.passYdsPerGame}</td>
          <td>{offense.passTds}</td>
          <td>{offense.sacksAllowed}</td>
          <td>{offense.turnovers}</td>
        </tr>
        <tr>
          <th>
            <ModalLink team={{ ...defense, unit: 'Defense' }} />
          </th>
          <td>{defense.pointsPerGame}</td>
          <td>{defense.rushYdsPerGame}</td>
          <td>{defense.rushTds}</td>
          <td>{defense.passYdsPerGame}</td>
          <td>{defense.passTds}</td>
          <td>{defense.sacks}</td>
          <td>{defense.turnovers}</td>
        </tr>
      </tbody>
    </table>
  );
};

const Vegas = ({ game }) => {
  return (
    <table className="table table-sm table-borderless centered-columns">
      <thead>
        <tr>
          <th width="33%">total</th>
          <th width="33%">{game.away}</th>
          <th width="33%">{game.home}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{game.total}</td>
          <td>{game.awayImplied}</td>
          <td>{game.homeImplied}</td>
        </tr>
      </tbody>
    </table>
  );
};

const OffensePace = ({ away, home }) => {
  return (
    <table className="table table-sm table-borderless centered-columns">
      <thead>
        <tr>
          <th width="33%">&nbsp;</th>
          <th width="33%">{away.name}</th>
          <th width="33%">{home.name}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>avgTOP</th>
          <td>{secondsToMinutes(away.avgTimeOfPossession)}</td>
          <td>{secondsToMinutes(home.avgTimeOfPossession)}</td>
        </tr>
        <tr>
          <th>plays</th>
          <td>{!!away.paceOfPlay && Math.trunc(away.avgTimeOfPossession / away.paceOfPlay)}</td>
          <td>{!!home.paceOfPlay && Math.trunc(home.avgTimeOfPossession / home.paceOfPlay)}</td>
        </tr>
        <tr>
          <th>pace/Play</th>
          <td>{away.paceOfPlay}</td>
          <td>{home.paceOfPlay}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default MatchupTab;
