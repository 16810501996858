import { useLineupEditorContext } from './LineupEditorContext';
import { asUsDollars } from 'app/util';
import EditorButtons from './EditorButtons';
import LineupPlayers from './LineupPlayers';

const BoardHeader = () => {

  const { lineup } = useLineupEditorContext();

  const remainingSalary = lineup.remainingSalary();
  const remainingSalaryPerOpen = lineup.remainingSalaryPerOpen();
  const remainingClassName = remainingSalary >= 0 ? 'fw-bold' : 'fw-bold negative-dollars';

  return (
    <>
      <div className="col-6">
        <h5 className="d-inline-block">Lineup</h5>
      </div>
      <div className="col-6 text-end">
        <div className="d-inline-block me-4">
          <span>Rem Salary: </span>
          <span className={remainingClassName}>{asUsDollars(remainingSalary)}</span>
        </div>
        <div className="d-inline-block ms-4">
          <span>Rem/Player: </span>
          <span className={remainingClassName}>{asUsDollars(remainingSalaryPerOpen)}</span>
        </div>
      </div>
    </>
  );
};

const LineupBoard = () => {

  return (
    <div className="border py-2 px-3">
      <div className="row mb-1">
        <BoardHeader />
      </div>
      <div className="row lineup-players">
        <LineupPlayers />
      </div>
      <div className="text-end">
        <EditorButtons />
      </div>
    </div>
  );
};

export default LineupBoard;
