import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setInterest } from 'app/api';
import { useSlate } from 'app/slates';

const NONE = 0;
const LIKE = 1;
const LOCK = 2;

const InterestIndicator = ({ player }) => {

  const slate = useSlate();
  const dispatch = useDispatch();

  const dispatchInterest = useCallback(interest => () => {
    dispatch(setInterest(slate.id, player, interest));
  }, [ dispatch, player, slate ]);

  return (
    <>
      <FontAwesomeIcon
        key="fa-square-check"
        icon="fa-solid fa-square-xmark"
        color="#ccc"
        className="pointer me-3"
        onClick={dispatchInterest(NONE)}
      />
      <FontAwesomeIcon
        key="fa-thumbs-up"
        icon="fa-solid fa-thumbs-up"
        color={`${player.interest === LIKE ? '#1a8ae5' : '#ccc'}`}
        className="pointer me-3"
        onClick={dispatchInterest(LIKE)}
      />
      <FontAwesomeIcon
        key="fa-lock"
        icon="fa-solid fa-lock"
        color={`${player.interest === LOCK ? '#e1c16e' : '#ccc'}`}
        className="pointer"
        onClick={dispatchInterest(LOCK)}
      />
    </>
  );
};

export default InterestIndicator;
