import { useLineupFields } from './fields';
import { DataTable } from 'app/components';
import { asUsDollars } from 'app/util';

const LineupSummary = ({ lineup, _edit, _delete }) => {

  const data = Object.keys(lineup.positions()).map(k => ({ ...lineup.get(k) }));
  const summaryDataset = { data, fields: useLineupFields() };

  return (
    <>
      <div className="row border g-0 py-2 px-3">
        <div className="col-6 fw-bold">
          <span>Rem Salary: </span>
          <span>{asUsDollars(lineup.remainingSalary())}</span>
        </div>
        <div className="col-6 text-end">
          <span className="link me-5" onClick={_edit}>edit</span>
          <span className="link" onClick={_delete}>delete</span>
        </div>
      </div>
      <div>
        <DataTable dataset={summaryDataset} config={{ sorting: false }} />
      </div>
    </>
  );
};

export default LineupSummary;
