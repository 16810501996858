import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { putShowdownPositionFilter, useShowdownPositionFilterData } from '../slates/redux';
import { CPT, FLEX, SHOWDOWN } from 'app/constants';

const ModalShowdownPositionFilter = ({ slate }) => {

  const dispatch = useDispatch();
  const position = useShowdownPositionFilterData(slate);
  const changeHandler = useCallback(e => dispatch(putShowdownPositionFilter(slate.id, e.target.value)), [ dispatch, slate ]);

  return (
    <tr>
      <td width="50%">
        <div className="form-check-right form-check-inline">
          <input
            type="radio"
            checked={position === CPT}
            className="form-check-input-right pointer"
            id="showdown-position-radio-modal-cpt"
            name="showdown-position-radio-modal"
            onChange={changeHandler}
            value={CPT}
          />
          <label className="form-check-label pointer" htmlFor="showdown-position-radio-modal-cpt">Captain</label>
        </div>
      </td>
      <td>
        <div className="form-check-right form-check-inline me-0">
          <input
            type="radio"
            checked={position === FLEX}
            className="form-check-input-right pointer"
            id="showdown-position-radio-modal-flex"
            name="showdown-position-radio-modal"
            onChange={changeHandler}
            value={FLEX}
          />
          <label className="form-check-label pointer" htmlFor="showdown-position-radio-modal-flex">Flex</label>
        </div>
      </td>
    </tr>
  );
};

const ModalShowdownPositionFilterWrapper = ({ slate }) => {
  return slate.contestType === SHOWDOWN && <ModalShowdownPositionFilter slate={slate} />;
};

export default ModalShowdownPositionFilterWrapper;
