import { useSlatePlayersDataset, useSlatePlayersFilters } from './redux';
import { DataTable } from 'app/components';
import SlatePlayersFilters from './SlatePlayersFilters';

const SlatePlayersDataTable = ({ slate, additionalFilters, config }) => {

  const dataset = useSlatePlayersDataset(slate);
  const filters = [ ...useSlatePlayersFilters(slate), ...additionalFilters ];
  const tableConfig = { recordKey: 'vendorId', ...config };

  return (
    <>
      <SlatePlayersFilters />
      <DataTable dataset={dataset} filters={filters} config={tableConfig} />
    </>
  );
};

export default SlatePlayersDataTable;
