import { useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { useAnalysisFields } from './fields';
import { useSlatePlayers } from 'app/players/slates/redux';
import { sort, sortedFields } from 'app/sorting';
import useLineupFactory from './LineupFactory';

/*
 * actions
 */
const SAVE_LINEUP = 'SAVE_LINEUP';
export const saveLineup = (slateId, lineup) => ({
  type: SAVE_LINEUP,
  slateId,
  lineup
});

const DELETE_LINEUP = 'DELETE_LINEUP';
export const deleteLineup = (slateId, lineupId) => ({
  type: DELETE_LINEUP,
  slateId,
  lineupId
});

const SORT_ANALYSIS_DATA = 'SORT_ANALYSIS_DATA';
const sortAnalysisData = (slateId, field) => ({
  type: SORT_ANALYSIS_DATA,
  slateId,
  field
});

/*
 * reducers
 */
const data = (state = {}, action) => {
  if (action.type === SAVE_LINEUP) {
    return _saveLineup(state, action);
  } else if (action.type === DELETE_LINEUP) {
    return _deleteLineup(state, action);
  } else {
    return state;
  }
};

const analysis = (state = {}, action) => {
  if (action.type === SORT_ANALYSIS_DATA) {
    return { ...state, [action.slateId]: { sortField: action.field } };
  } else {
    return state;
  }
};

export default combineReducers({ data, analysis });

/*
 * helpers
 */
const _saveLineup = (state, action) => {
  return { ...state, [action.slateId]: { ...state[action.slateId], [action.lineup.id()]: action.lineup } };
};

const _deleteLineup = (state, action) => {
  const _lineups = { ...state[action.slateId] };
  delete _lineups[action.lineupId];
  return { ...state, [action.slateId]: _lineups };
};

/*
 * hooks
 */
export const useLineups = slate => {
  const lineupFactory = useLineupFactory(slate);
  const literals = useSelector(({ lineups }) => lineups.data[slate.id]);
  return literals ? Object.values(literals).map(lineupFactory.make) : [];
};

export const useLineup = (slate, lineupId) => {
  const lineupFactory = useLineupFactory(slate);
  const literal = useSelector(({ lineups }) => lineups.data[slate.id]?.[lineupId]);
  return literal ? lineupFactory.make(literal) : undefined;
};

export const useAnalysisDataset = slate => {

  const lineups = useLineups(slate);

  const analysis = lineups
    .flatMap(lineup => Object.values(lineup.players()))
    .reduce((analysis, player) => {
      const count = (analysis[player.vendorId]?.count || 0) + 1;
      return {
        ...analysis,
        [player.vendorId]: {
          count,
          percentage: Math.trunc((count / lineups.length).toFixed(2) * 100)
        }
      };
    }, {});

  const players = useSlatePlayers(slate)
    .filter(player => analysis[player.vendorId] || player.target || player.interest)
    .map(player => ({
      diff: (analysis[player.vendorId]?.count || 0) - (player.target || 0),
      ...{ count: 0, percentage: 0 },
      ...player,
      ...analysis[player.vendorId]
    }));

  const sortField = useSelector(({ lineups }) => lineups.analysis[slate.id])?.sortField;

  return {
    id: slate.id,
    data: sortField ? sort(players, sortField) : players,
    fields: sortedFields(useAnalysisFields(), sortField),
    sort: sortAnalysisData
  };
};
