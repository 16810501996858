import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLineupEditorContext } from './LineupEditorContext';
import { saveLineup } from '../redux';
import { useSlate } from 'app/slates';
import { epochTime } from 'app/util';
import { LINEUPS } from 'app/views';
import OptimizerButton from './OptimizerButton';

const newLineupId = epochTime;

const SaveButton = ({ navigateToLineups }) => {

  const slate = useSlate();
  const dispatch = useDispatch();
  const { lineup } = useLineupEditorContext();

  const save = useCallback(lineup => () => {
    lineup.id(lineup.id() || newLineupId());
    dispatch(saveLineup(slate.id, lineup));
    navigateToLineups();
  }, [ dispatch, navigateToLineups, slate ]);

  return (
    <button
      type="button"
      className="btn btn-outline-success d-inline-block me-3"
      disabled={!lineup.isValid()}
      onClick={save(lineup)}
    >
      Save
    </button>
  );
};

const QuitButton = ({ navigateToLineups }) => {
  return (
    <button
      type="button"
      className="btn btn-outline-danger d-inline-block"
      onClick={navigateToLineups}
    >
      Quit
    </button>
  );
};

const EditorButtons = () => {

  const navigateTo = useNavigate();
  const navigateToLineups = useCallback(() => navigateTo(LINEUPS.route), [ navigateTo ]);

  return (
    <>
      <OptimizerButton />
      <SaveButton navigateToLineups={navigateToLineups} />
      <QuitButton navigateToLineups={navigateToLineups} />
    </>
  );
};

export default EditorButtons;
