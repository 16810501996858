import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { GameBox } from 'app/components';
import { clearFilter, putFilter, useFilterData } from 'app/filters';
import { useGames } from 'app/games';
import { useSlate } from 'app/slates';

export const SLATE_TEAMS = 'slateTeams';

const putSlateTeamsFilter = (slateId, teams) => putFilter({
  slateId,
  filter: {
    [SLATE_TEAMS]: {
      data: teams
    }
  }
});

const useSlateTeamsFilterData = slate => {
  return useFilterData(slate, SLATE_TEAMS)?.data || [];
};

const clearSlatesTeamsFilter = slateId => clearFilter(slateId, SLATE_TEAMS);

const SlateTeamsFilter = () => {

  const dispatch = useDispatch();

  const slate = useSlate();
  const games = useGames(slate);
  const teams = games.flatMap(g => [ g.home, g.away ]);
  const active = useSlateTeamsFilterData(slate);

  const deriveFilterTeams = (...clicked) => {
    const filterTeams = [ ...active ];
    clicked.every(team => filterTeams.includes(team))
      ? clicked.forEach(team => filterTeams.splice(filterTeams.indexOf(team), 1))
      : clicked.forEach(team => !filterTeams.includes(team) && filterTeams.push(team));
    return filterTeams;
  };

  const handlers = {
    game: game => dispatch(putSlateTeamsFilter(slate.id, deriveFilterTeams(game.away, game.home))),
    team: team => dispatch(putSlateTeamsFilter(slate.id, deriveFilterTeams(team)))
  };

  const dispatchPutSlateTeamsFilter = useCallback(() => dispatch(putSlateTeamsFilter(slate.id, teams)), [ dispatch, slate, teams ]);
  const dispatchClearSlatesTeamsFilter = useCallback(() => dispatch(clearSlatesTeamsFilter(slate.id)), [ dispatch, slate ]);

  return (
    <div className="d-inline-block">
      <div className="border-start">
        { games.map(game => <GameBox key={game.home} game={game} activeTeams={active} clickHandlers={handlers} />) }
      </div>
      <div className={`px-1 py-1 text-end ${games.length > 1 ? '' : 'invisible'}`}>
        <span className="link m-3" onClick={dispatchPutSlateTeamsFilter}>select all</span>
        <span className="link" onClick={dispatchClearSlatesTeamsFilter}>clear</span>
      </div>
    </div>
  );
};

export default SlateTeamsFilter;
