import { createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLineup } from '../redux';
import { useSlate } from 'app/slates';
import useLineupFactory from '../LineupFactory';

const LineupEditorContext = createContext(null);

export const useLineupEditorContext = () => useContext(LineupEditorContext);

export const LineupEditorProvider = ({ children }) => {

  const slate = useSlate();
  const lineupFactory = useLineupFactory(slate);
  const { lineupId } = useParams();
  const [ lineup, setLineup ] = useState(useLineup(slate, lineupId) || lineupFactory.make());
  const initialState = useMemo(() => ({ lineup, setLineup }), [ lineup, setLineup ]);

  return (
    <LineupEditorContext.Provider value={initialState}>
      { children }
    </LineupEditorContext.Provider>
  );
};
