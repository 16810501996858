import { FilterToggleBox } from 'app/components';
import { clearFilter, putFilter, useFilterData } from 'app/filters';

export const TARGETS = 'targets';

const putTargetsFilter = slateId => putFilter({
  slateId,
  filter: {
    [TARGETS]: {}
  }
});

const clearTargetsFilter = slateId => clearFilter(slateId, TARGETS);

const useTargetsFilterIsEnabled = slate => {
  return !!useFilterData(slate, TARGETS);
};

const TargetsFilter = ({ slate }) => {

  return (
    <FilterToggleBox
      label="Targets"
      slate={slate}
      enabled={useTargetsFilterIsEnabled(slate)}
      putFilter={putTargetsFilter}
      clearFilter={clearTargetsFilter}
    />
  );
};

export default TargetsFilter;
