import { CheckBox } from 'app/components';
import { QB, RB, TE, WR } from 'app/constants';
import { putFilter, useFilterData } from 'app/filters';
import { useSlate } from 'app/slates';

export const MULTI_POSITION = 'multiPosition';

const putMultiPositionFilter = (slateId, data) => putFilter({
  slateId,
  filter: {
    [MULTI_POSITION]: {
      data
    }
  }
});

export const useMultiPositionFilterData = slate => {
  return useFilterData(slate, MULTI_POSITION)?.data || {};
};

const MultiPositionFilter = () => {

  const slate = useSlate();
  const filterData = useMultiPositionFilterData(slate);

  const action = position => filterData[position]
    ? () => putMultiPositionFilter(slate.id, { ...filterData, [position]: false })
    : () => putMultiPositionFilter(slate.id, { ...filterData, [position]: true });

  return (
    <div className="d-inline me-5">
      {
        [ QB, RB, WR, TE ].map(p => (
          <span key={p} className="me-5">
            <CheckBox action={action(p)} checked={filterData[p]} label={p} />
          </span>))
      }
    </div>
  );
};

export default MultiPositionFilter;
