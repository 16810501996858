import Body from './Body';
import Footer from './Footer';
import Header from './Header';
import ModalMessageProvider from './ModalMessageContext';

const View = () => {
  return (
    <div className="h-100 d-flex flex-column">
      <ModalMessageProvider>
        <Header />
        <Body />
        <Footer />
      </ModalMessageProvider>
    </div>
  );
};

export default View;
