import { useMatchupsDataset } from './redux';
import { DataTable } from 'app/components';
import { useSlate, useSlateTeamsFilter } from 'app/slates';

const MatchupsView = () => {

  const slate = useSlate();

  const dataset = useMatchupsDataset(slate.week);
  const filters = useSlateTeamsFilter(slate);
  const tableConfig = { classNames: 'border' };

  return (
    <div className="my-4">
      <DataTable dataset={dataset} filters={filters} config={tableConfig} />
    </div>
  );
};

export default MatchupsView;
