import { useSlate, useSlateTeamsFilter } from 'app/slates';
import SlatePlayersDataTable from './SlatePlayersDataTable';

const SlatePlayersView = () => {

  const slate = useSlate();

  return (
    <div className="my-4">
      <SlatePlayersDataTable slate={slate} additionalFilters={useSlateTeamsFilter(slate)} />
    </div>
  );
};

export default SlatePlayersView;
