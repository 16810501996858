import { library as icons } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckSquare,
  faCircleMinus,
  faCirclePlus,
  faLock,
  faSort,
  faSortDown,
  faSortUp,
  faSquareXmark,
  faThumbsUp
} from '@fortawesome/free-solid-svg-icons';

icons.add(
  faCheckSquare,
  faCircleMinus,
  faCirclePlus,
  faSquareXmark,
  faLock,
  faSort,
  faSortUp,
  faSortDown,
  faThumbsUp
);
