import { useInjuryReportDataset, useInjuryReportFilters } from './redux';
import { DataTable } from 'app/components';
import { HomeAwayFilter } from 'app/games';
import MinGamesPlayedFilter from './MinGamesPlayedFilter';
import MinSnapPercentageFilter from './MinSnapPercentageFilter';
import StatusFilter from './StatusFilter';

const InjuryReport = ({ slate, additionalFilters }) => {

  const dataset = useInjuryReportDataset(slate.week);

  const filters = [
    ...useInjuryReportFilters(slate),
    ...additionalFilters
  ];

  const tableConfig = {
    classNames: 'border'
  };

  return (
    <div className="my-4">
      <div className="row mb-3">
        <div className="col ms-2">
          <div className="mb-3">
            <HomeAwayFilter slate={slate} tab="injury-report" />
          </div>
          <div>
            <StatusFilter slate={slate} />
            <MinGamesPlayedFilter slate={slate} />
            <MinSnapPercentageFilter slate={slate} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <DataTable dataset={dataset} filters={filters} config={tableConfig} />
        </div>
      </div>
    </div>
  );
};

export default InjuryReport;
