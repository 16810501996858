import { CLASSIC } from 'app/constants';
import ClassicLineup from './model/ClassicLineup';
import ShowdownLineup from './model/ShowdownLineup';

const useLineupFactory = slate => {

  const make = slate.contestType === CLASSIC
    ? source => new ClassicLineup(source)
    : source => new ShowdownLineup(source);

  return { make };
};

export default useLineupFactory;
