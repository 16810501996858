/**
 * At its core, this is a matrix of lineups to be populated via a dynamic programming
 * approach to solving for an optimal lineup; i.e., a common "knapsack problem" solution.
 */
export const Lineups = class {

  constructor (lineupFactory) {
    this._lineupFactory = lineupFactory;
    this._lineups = [ [] ];
  }


  add (row, column, lineup) {
    if (!this._lineups[row]) {
      this._lineups[row] = [];
    }
    this._lineups[row][column] = lineup;
  }

  previous (row, column) {
    if (row === 0 || column <= 0 || !this._lineups[row - 1][column]) {
      return this._lineupFactory.makeLineup();
    }
    return this._lineupFactory.makeLineup([ ...this._lineups[row - 1][column].players() ]);
  }

  optimal () {
    return this._lineups[this._lineups.length - 1]
      .filter(lineup => lineup.isValid())
      .reduce((optimal, current) => {
        return current.points() > optimal.points() ? current : optimal;
      }, this._lineupFactory.makeLineup());
  }
};
