const PracticeReport = ({ report }) => {
  return (
    <table className="table table-sm text-center align-middle">
      <thead className="table-light">
        <tr>
          <th>Monday</th>
          <th>Tuesday</th>
          <th>Wednesday</th>
          <th>Thursday</th>
          <th>Friday</th>
          <th>Saturday</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{report.monday || '-'}</td>
          <td>{report.tuesday || '-'}</td>
          <td>{report.wednesday || '-'}</td>
          <td>{report.thursday || '-'}</td>
          <td>{report.friday || '-'}</td>
          <td>{report.saturday || '-'}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default PracticeReport;
