import { useCallback } from 'react';
import { QB, RB, TE, WR } from 'app/constants';
import { indication } from 'app/injuries';
import { usePlayers, useSelectedPlayerIdDispatch, useShowPlayerModalDispatch } from 'app/players';
import { useDepthChart } from 'app/teams';

const PlayerDepth = ({ pd, week }) => {

  const players = usePlayers(week);

  const player = players[pd.id];
  const statusIndication = indication(player.status);

  const dispatchSelectedPlayerId = useSelectedPlayerIdDispatch();
  const dispatchShowPlayerModal = useShowPlayerModalDispatch();

  const onClick = useCallback(() => {
    dispatchSelectedPlayerId(player.id);
    dispatchShowPlayerModal(true);
  }, [ dispatchSelectedPlayerId, dispatchShowPlayerModal, player ]);

  return (
    <tr key={pd.id}>
      <td>{pd.depth}.</td>
      <td>
        <span className="link" onClick={onClick}>{player.name}</span>
        <span className="status-indication">{statusIndication}</span>
      </td>
    </tr>
  );
};

const PositionDepth = ({ depthChart, position, week }) => {
  return (
    <div key={position} className="col-3">
      <table className="table table-sm table-borderless d-inline m-3">
        <thead>
          <tr>
            <th>{position}</th>
          </tr>
        </thead>
        <tbody>
          { depthChart[position].map(pd => <PlayerDepth key={pd.id} pd={pd} week={week} />) }
        </tbody>
      </table>
    </div>
  );
};

const DepthChart = ({ team, week }) => {

  const depthChart = useDepthChart(team, week);

  return (
    <div className="my-4">
      <h5 className="mx-3">{team}</h5>
      <div className="row">
        { [ QB, RB, WR, TE ].map(position => (
          <PositionDepth
            key={`${team.id}-${position}-${week}`}
            depthChart={depthChart}
            position={position}
            week={week}
          />
        )) }
      </div>
    </div>
  );
};

const DepthChartsTab = ({ away, home, week }) => {

  return (
    <>
      <DepthChart team={away} week={week} />
      <DepthChart team={home} week={week} />
    </>
  );
};

export default DepthChartsTab;
