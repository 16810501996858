import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import { useMember } from 'app/auth';
import { DEFAULT_AUTHORIZED_VIEW, LOGIN, MENU_ITEMS, SLATE_VIEWS } from 'app/views';
import SlateView from 'app/slates/SlateView';

const Authorized = () => {
  return (
    <>
      <NavMenu />
      <AuthorizedRoutes />
    </>
  );
};

const NavMenu = () => {
  return (
    <div className="col-2 bg-secondary py-3">
      <nav className="nav flex-column">
        { MENU_ITEMS.map(view => <NavLink key={view.id} to={view.route} className="nav-link">{view.name}</NavLink>) }
      </nav>
    </div>
  );
};

const AuthorizedRoutes = () => {
  return (
    <main className="col-10 p-5">
      <Routes>
        {
          SLATE_VIEWS.map(view => (
            <Route
              key={view.id}
              path={view.route}
              element={(
                <AuthorizedRoute>
                  <SlateView header={view.name} component={view.component} />
                </AuthorizedRoute>
              )}
            />
          ))
        }
        <Route path="*" element={<Navigate to={DEFAULT_AUTHORIZED_VIEW.route} replace />} />
      </Routes>
    </main>
  );
};

const AuthorizedRoute = ({ children }) => {
  const member = useMember();
  return member.authorized
    ? children
    : <Navigate to={LOGIN.route} />;
};

export default Authorized;
