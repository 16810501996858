import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { requestLogOut } from './redux';
import authText from './text.json';

const LogOutLink = () => {

  const dispatch = useDispatch();
  const className = 'btn btn-link text-light text-decoration-none';
  const onClick = useCallback(() => dispatch(requestLogOut()), [ dispatch ]);

  return <button type="button" className={className} onClick={onClick}>{authText.logOut}</button>;
};

export default LogOutLink;
