import { HomeAwayFilter } from 'app/games';
import { useSlate } from 'app/slates';
import { useIsView } from 'app/view';
import { GAMES } from 'app/views';
import AvgSnapPercentageFilter from './AvgSnapPercentageFilter';
import AvgTimesFourFilter from './AvgTimesFourFilter';
import InterestsFilter from './InterestsFilter';
import MaxTimesFourFilter from './MaxTimesFourFilter';
import PointsFilter from './PointsFilter';
import SalaryFilter from './SalaryFilter';
import ShowdownPositionFilter from './ShowdownPositionFilter';
import SlatePlayersPositionFilter from './SlatePlayersPositionFilter';
import SlatePlayersFieldGroupSelector from './SlatePlayersFieldGroupSelector';
import TargetsFilter from './TargetsFilter';

const SlatePlayersFilters = () => {

  const slate = useSlate();

  return (
    <>
      <div className="mb-4">
        {
          useIsView(GAMES) &&
          <div className="ms-2 mb-3">
            <HomeAwayFilter slate={slate} tab="players" />
          </div>
        }
        <div className="ms-2 mb-3">
          <ShowdownPositionFilter slate={slate} />
          <InterestsFilter slate={slate} />
          <PointsFilter slate={slate} />
          <TargetsFilter slate={slate} />
        </div>
        <div className="d-flex align-items-center justify-content-between ms-2 me-3">
          <div>
            <SalaryFilter slate={slate} />
            <MaxTimesFourFilter slate={slate} />
            <AvgTimesFourFilter slate={slate} />
            <AvgSnapPercentageFilter slate={slate} />
          </div>
          <div>
            <SlatePlayersFieldGroupSelector />
          </div>
        </div>
      </div>
      <SlatePlayersPositionFilter />
    </>
  );
};

export default SlatePlayersFilters;
