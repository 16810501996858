import { useCallback } from 'react';
import { useSelectedPlayerIdDispatch, useShowPlayerModalDispatch } from './modal';
import { putCustom } from 'app/api';
import { indication } from 'app/injuries';
import { useSlate } from 'app/slates';
import { asUsDollars } from 'app/util';
import { fourTimesValue } from 'app/util/calculations';
import InterestIndicator from './InterestIndicator';

const useFieldDictionary = () => {
  const slate = useSlate();
  const select = useSelectedPlayerIdDispatch();
  const showModal = useShowPlayerModalDispatch();

  return {
    interest: {
      name: 'interest',
      type: 'component',
      header: '',
      noSorting: true,
      Component: ({ record }) => <InterestIndicator player={record} />
    },
    name: {
      name: 'name',
      type: 'component',
      header: 'name',
      cellClassname: 'text-start',
      order: 1,
      orderedBy: 0,
      Component: ({ record }) => {
        const statusIndication = indication(record.status);
        const onClick = useCallback(() => {
          select(record.id);
          showModal(true);
        }, [ record ]);
        return (
          <>
            <span className="link" onClick={onClick}>{record.name}</span>
            <span className="status-indication">{statusIndication}</span>
          </>
        );
      }
    },
    position: {
      name: 'position',
      header: 'pos',
      order: 1,
      orderedBy: 0
    },
    team: {
      name: 'team',
      header: 'tm',
      order: 1,
      orderedBy: 0
    },
    salary: {
      name: 'salary',
      type: 'custom',
      header: 'salary',
      order: -1,
      orderedBy: 0,
      render: player => asUsDollars(player.salary)
    },
    fourTimesValue: {
      name: 'fourTimesValue',
      type: 'custom',
      alias: 'salary',
      header: 'x4',
      order: -1,
      orderedBy: 0,
      render: player => fourTimesValue(player.salary)
    },
    maxFps: {
      name: 'maxFps',
      header: 'max pts',
      order: -1,
      orderedBy: 0
    },
    avgFps: {
      name: 'avgFps',
      header: 'avg pts',
      order: -1,
      orderedBy: 0
    },
    opponent: {
      name: 'opponent',
      header: 'opp',
      order: 1,
      orderedBy: 0
    },
    oppPtsAgst: {
      name: 'oppPtsAgst',
      header: 'opa',
      order: -1,
      orderedBy: 0
    },
    completions: {
      name: 'completions',
      header: 'cmp',
      order: -1,
      orderedBy: 0
    },
    passAttempts: {
      name: 'passAttempts',
      header: 'att',
      order: -1,
      orderedBy: 0
    },
    passYds: {
      name: 'passYds',
      header: 'yds',
      order: -1,
      orderedBy: 0
    },
    ydsPerPass: {
      name: 'ydsPerPass',
      header: 'avg',
      order: -1,
      orderedBy: 0
    },
    passYdsPerGame: {
      name: 'passYdsPerGame',
      header: 'yds/gm',
      order: -1,
      orderedBy: 0
    },
    passTds: {
      name: 'passTds',
      header: 'tds',
      order: -1,
      orderedBy: 0
    },
    interceptions: {
      name: 'interceptions',
      header: 'ints',
      order: -1,
      orderedBy: 0
    },
    rushAttempts: {
      name: 'rushAttempts',
      header: 'rsh',
      order: -1,
      orderedBy: 0
    },
    avgRushAttempts: {
      name: 'avgRushAttempts',
      header: 'rsh/gm',
      order: -1,
      orderedBy: 0
    },
    rushYds: {
      name: 'rushYds',
      header: 'yds',
      order: -1,
      orderedBy: 0
    },
    ydsPerRush: {
      name: 'ydsPerRush',
      header: 'avg',
      order: -1,
      orderedBy: 0
    },
    rushYdsPerGame: {
      name: 'rushYdsPerGame',
      header: 'yds/gm',
      order: -1,
      orderedBy: 0
    },
    rushTds: {
      name: 'rushTds',
      header: 'tds',
      order: -1,
      orderedBy: 0
    },
    targets: {
      name: 'targets',
      header: 'tgts',
      order: -1,
      orderedBy: 0
    },
    avgTargets: {
      name: 'avgTargets',
      header: 'tgts/gm',
      order: -1,
      orderedBy: 0
    },
    receptions: {
      name: 'receptions',
      header: 'rec',
      order: -1,
      orderedBy: 0
    },
    receivingYds: {
      name: 'receivingYds',
      header: 'yds',
      order: -1,
      orderedBy: 0
    },
    ydsPerReception: {
      name: 'ydsPerReception',
      header: 'yds/rec',
      order: -1,
      orderedBy: 0
    },
    recYdsPerGame: {
      name: 'recYdsPerGame',
      header: 'recYds/gm',
      order: -1,
      orderedBy: 0
    },
    receivingTds: {
      name: 'receivingTds',
      header: 'tds',
      order: -1,
      orderedBy: 0
    },
    offensiveTds: {
      name: 'offensiveTds',
      header: 'offTds',
      order: -1,
      orderedBy: 0
    },
    fumbles: {
      name: 'fumbles',
      header: 'fbls',
      order: -1,
      orderedBy: 0
    },
    avgPercentSnaps: {
      name: 'avgPercentSnaps',
      header: 'snap %',
      order: -1,
      orderedBy: 0
    },
    metric: {
      name: 'metric',
      header: 'metric',
      order: -1,
      orderedBy: 0
    },
    projection: {
      name: 'projection',
      header: 'prjctn',
      order: -1,
      orderedBy: 0
    },
    points: {
      name: 'points',
      type: 'input',
      header: 'points',
      order: -1,
      orderedBy: 0,
      blurAction: (player, value) => putCustom(slate, player, 'points', value)
    },
    target: {
      name: 'target',
      type: 'input',
      header: 'target',
      order: -1,
      orderedBy: 0,
      blurAction: (player, value) => putCustom(slate, player, 'target', value)
    }
  };
};

const fieldIdsFor = fieldGroupId => {
  return {
    passing: [
      'name',
      'position',
      'team',
      'completions',
      'passAttempts',
      'passYds',
      'ydsPerPass',
      'passTds',
      'interceptions',
      'fumbles',
      'passYdsPerGame'
    ],
    rushing: [
      'name',
      'position',
      'team',
      'rushAttempts',
      'rushYds',
      'ydsPerRush',
      'rushTds',
      'fumbles',
      'avgRushAttempts',
      'rushYdsPerGame'
    ],
    receiving: [
      'name',
      'position',
      'team',
      'targets',
      'avgTargets',
      'receptions',
      'receivingYds',
      'ydsPerReception',
      'recYdsPerGame',
      'receivingTds',
      'fumbles'
    ],
    scoring: [
      'name',
      'position',
      'team',
      'rushTds',
      'receivingTds',
      'offensiveTds'
    ],
    dfs: [
      'interest',
      'name',
      'position',
      'team',
      'salary',
      'fourTimesValue',
      'maxFps',
      'avgFps',
      'opponent',
      'oppPtsAgst',
      'avgRushAttempts',
      'avgTargets',
      'avgPercentSnaps',
      'metric',
      'points',
      'target'
    ]
  }[fieldGroupId];
};

const useFieldsFor = fieldGroupId => useFields(fieldIdsFor(fieldGroupId));

export const useFields = fieldIds => {
  const dict = useFieldDictionary();
  return fieldIds.map(id => dict[id]);
};


export const defaultPlayerStatsFieldGroup = () => ({
  id: 'passing',
  text: 'Passing'
});

export const playerStatsFieldGroups = () => [
  defaultPlayerStatsFieldGroup(),
  {
    id: 'rushing',
    text: 'Rushing'
  },
  {
    id: 'receiving',
    text: 'Receiving'
  },
  {
    id: 'scoring',
    text: 'Scoring'
  }
];

export const usePlayerStatsFields = useFieldsFor;


export const defaultSlatePlayersFieldGroup = () => ({
  id: 'dfs',
  text: 'DFS'
});

export const slatePlayersFieldGroups = () => [
  defaultSlatePlayersFieldGroup(),
  ...playerStatsFieldGroups()
];

export const useSlatePlayersFields = fieldGroup => useFieldsFor(fieldGroup.id);
